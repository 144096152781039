<template>
  <div class="wrapper">
    <ItemSelectionDialog
      :is-active="isItemSelectionDialogActive"
      :instruction="selectedInstruction"
      @onClose="onItemSelectionClose"
      @onNewItem="onNewItem"
      @onUpdateItem="onUpdateItem"
      @onDeleteItem="onDeleteItem"
    />
    <b-button
      expanded
      :disabled="isTaskCompleted"
      type="is-light"
      @click="openSelectInstruction"
      class="create-new"
    >
      <div class="btn-center-content">
        <b-icon icon="plus" size="fa-2x"> </b-icon> Create new
      </div>
    </b-button>

    <div class="items-container">
      <div
        class="instruction full"
        v-for="(instruction, index) in taskQuestions"
        @click="openInstruction(instruction)"
        :id="'instruction_' + instruction._id"
        :key="instruction._id"
        draggable="true"
        @dragstart="dragStart($event, instruction._id)"
        @dragover.prevent
        @drop="drop($event, instruction._id)"
        @dragenter.prevent="dragEnter($event, instruction._id)"
        @dragleave.prevent="dragLeave($event, instruction._id)"
      >
        <div class="instruction-title">
          <b-icon
            v-if="questionLocked(instruction)"
            icon="account-lock"
            class="locked-question"
          />
          {{ instruction.label }}. {{ instruction.question }}
          <div v-if="instruction.isPictureMandatory" class="req-picture">
            Picture required.
          </div>
        </div>

        <div style="display: flex; flex-direction: row; width: 100%">
          <div class="question-details" v-if="instruction.type === 'action'">
            Action question
          </div>
          <div class="question-details" v-if="instruction.type === 'yesno'">
            Yes/No
          </div>
          <div
            class="question-details"
            v-if="instruction.type === 'textnumber'"
          >
            Text or Number
          </div>
          <div
            v-if="instruction.type === 'multiplechoice'"
            class="question-details"
          >
            <!-- Multiple choice options rendering -->
            <span
              v-for="(option, optionIndex) in instruction.multipleChoices"
              :key="optionIndex"
              class="option"
            >
              {{ String.fromCharCode(65 + optionIndex) }}.
              {{ option.text?.defaultMC || option?.text || option.defaultMC }}
            </span>
          </div>
          <div
            v-if="['tablequestion', 'importcsv'].includes(instruction.type)"
            class="question-details"
          >
            <div class="table-description">
              Table {{ instruction.dimensions }}
            </div>

            <div
              v-if="instruction.type === 'tablequestion'"
              class="image-container"
            >
              <div v-if="getTableImage(instruction)">
                <img :src="getTableImage(instruction)" alt="Table image" />
              </div>
              <div v-else>
                <b-icon icon="image-area" size="is-medium"></b-icon>
              </div>
            </div>
            <TableQuestionLayout
              :rows="instruction.tableRow"
              :cols="instruction.tableColumn"
              :data="instruction.tableData"
              displayType="preview"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemSelectionDialog from './components/ItemSelectionDialog';
import {
  CREATE_QUESTION,
  CREATE_QUESTIONS,
  DELETE_QUESTION,
  GET_QUESTIONS,
  LOCK_QUESTION,
  SAVE_QUESTIONS,
  UPDATE_QUESTION,
} from '../../../store/questions/actions/actionTypes';
import {
  GET_TASK_QUESTIONS,
  FETCH_QUESTION_LABEL,
} from '../../../store/questions/getters/getterTypes';

import {
  ADD_QUESTION,
  CHANGE_QUESTIONS_ORDER,
  QUESTIONS_ORDER,
  REMOVE_QUESTION,
  MUTATION_UPDATE_QUESTION,
} from '../../../store/questions/mutations/mutationTypes';
import TableQuestionLayout from './components/TableQuestionLayout';
import {
  WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION,
  WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION,
} from '../../../store/websocket/actions/actionTypes';
import { GET_WEBSOCKET_CONNECTION_ID } from '../../../store/websocket/getters/getterTypes';

export default {
  components: {
    ItemSelectionDialog,
    TableQuestionLayout,
  },
  data() {
    return {
      isItemSelectionDialogActive: false,
      selectedInstruction: null,
      draggingIndex: -1, // Index of the item being dragged
      draggedElement: null, // Store the reference to the dragged element
      enteredElement: null,
    };
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  async created() {
    const wsMappings = {
      questionCreated: this.wsQuestionCreated,
      questionDeleted: this.wsQuestionDeleted,
      questionUpdated: this.wsQuestionUpdated,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },

  beforeDestroy() {
    const wsMappings = {
      questionCreated: this.wsQuestionCreated,
      questionDeleted: this.wsQuestionDeleted,
      questionUpdated: this.wsQuestionUpdated,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },
  computed: {
    wsConnectionId() {
      return this.$store.getters[GET_WEBSOCKET_CONNECTION_ID];
    },
    taskQuestions() {
      return this.$store.getters[GET_TASK_QUESTIONS];
    },
    isTaskCompleted() {
      return this.task.workStatusCode
        ? this.task.workStatusCode.toString() === '2'
        : false;
    },
  },
  methods: {
    wsQuestionUpdated(data) {
      this.$store.commit(MUTATION_UPDATE_QUESTION, data);
    },
    wsQuestionDeleted(data) {
      this.$store.commit(REMOVE_QUESTION, data);
    },
    wsQuestionCreated(data) {
      this.$store.commit(ADD_QUESTION, data);
    },
    questionLocked(instruction) {
      if (!instruction.lockedByWS) {
        return false;
      }

      return instruction.lockedByWS !== this.wsConnectionId;
    },
    dragStart(event, index) {
      this.draggingIndex = index;
      this.draggedElement = event.target;
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('text/html', this.draggedElement.outerHTML);
      this.draggedElement.style.opacity = '0.4';
    },
    dragEnter(event, index) {
      this.enteredElement = event.target;
      const targetElement = event.target.closest('.instruction'); // Ensure this targets the .instruction element
      if (this.draggingIndex !== index) {
        targetElement.classList.add('shake');
      }
    },
    dragLeave(event) {
      if (!event.target.classList.contains('instruction')) {
        return;
      }

      if (
        this.enteredElement &&
        this.enteredElement.parentNode.classList.contains('instruction')
      ) {
        return;
      }
      event.target.classList.remove('shake');
    },
    drop(event, index) {
      event.preventDefault();
      const targetElement = event.target.closest('.instruction');
      if (targetElement) {
        targetElement.classList.remove('shake');
      }
      this.draggedElement.style.opacity = '1'; // Reset the opacity of the dragged element
      event.target.style.background = ''; // Reset background of the drop target
      this.moveItem(this.draggingIndex, index);
      this.draggingIndex = -1; // Reset dragging index
      this.draggedElement = null; // Clear the reference to the dragged element
    },
    moveItem(from, to) {
      this.$store.commit(CHANGE_QUESTIONS_ORDER, {
        question: from,
        targetQuestion: to,
      });

      this.$store.dispatch(SAVE_QUESTIONS, {
        taskId: this.task._id,
        data: this.taskQuestions,
      });
    },
    getTableImage(instruction) {
      // For local env we need to point to the local s3
      if (instruction.tableImg && window.location.host.includes('localhost')) {
        return `http://localhost:4566/images${instruction.tableImg}`;
      }

      return instruction.tableImg;
    },
    openInstruction(instruction) {
      if (this.isTaskCompleted) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('task_completed_message'),
          type: 'is-warning',
        });
        return;
      }

      this.selectedInstruction = instruction;
      this.isItemSelectionDialogActive = true;

      if (!this.selectedInstruction.lockedByWS) {
        this.$store.dispatch(LOCK_QUESTION, {
          questionId: this.selectedInstruction._id,
        });
      }
    },
    async onNewItem(itemData) {
      await this.$store.dispatch(CREATE_QUESTION, {
        taskId: this.task._id,
        question: {
          ...itemData,
          label: this.$store.getters[FETCH_QUESTION_LABEL],
        },
      });

      this.isItemSelectionDialogActive = false;
    },
    async onDeleteItem(itemData) {
      await this.$store.dispatch(DELETE_QUESTION, itemData._id);
      this.$store.commit(QUESTIONS_ORDER);
      const questionLength = this.$store.state.QuestionsModule.questions.length;
      if (questionLength > 0) {
        this.$store.dispatch(SAVE_QUESTIONS, {
          taskId: this.task._id,
          data: this.taskQuestions,
        });
      }
      this.isItemSelectionDialogActive = false;
    },
    async onUpdateItem(itemData) {
      await this.$store.dispatch(UPDATE_QUESTION, itemData);
      await this.$store.dispatch(GET_QUESTIONS, this.task._id);
      this.isItemSelectionDialogActive = false;
    },

    openSelectInstruction() {
      this.selectedInstruction = null;
      this.isItemSelectionDialogActive = true;
    },
    onItemSelectionClose() {
      this.isItemSelectionDialogActive = false;
    },
    async forceSave() {
      await this.$store.dispatch(CREATE_QUESTIONS, {
        taskId: this.task._id,
        questions: this.taskQuestions,
      });
      await this.$store.dispatch(GET_QUESTIONS, this.task._id);
    },
  },
};
</script>

<style scoped>
.locked-question {
  color: red;
  margin-right: 10px;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.shake {
  animation: shake 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.instruction.full.drag-over {
  background-color: #f0f0f0; /* Temporary background color when dragging over */
}

.create-new {
  margin-bottom: 20px;
  min-height: 75px;
  font-size: larger;
  font-weight: bold;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
}

button span {
  width: 100%;
}

.full-width {
  width: 100%;
}

.subtitle {
  display: flex;
  flex-direction: row;
}

.table-description {
  align-items: start;
  display: flex;
  width: 100%;
}

.question-details {
  font-size: large;
  color: gray;
}

.req-picture {
  margin-left: auto;
  color: gray;
  font-style: italic;
  font-size: medium;
  display: flex;
  align-items: center;
}

.items-container {
  gap: 10px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
  min-height: calc(100vh - 245px);
  max-height: calc(100vh - 245px);
  padding: 0 10px;
}

@media only screen and (max-device-width: 1600px) and (orientation: portrait),
  only screen and (max-device-width: 1600px) and (orientation: landscape) {
  .items-container {
    gap: 10px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);
    padding: 0 10px;
  }
}

.instruction {
  font-size: larger;
  font-weight: bold;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.instruction:hover {
  background: #f5f5f5a6;
}

.instruction-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: larger;
  align-items: center;
}

.instruction:hover {
  cursor: pointer;
}

.justify-start {
  justify-content: start;
}

.wrapper {
  gap: 20px;
  padding: 20px 10px;
  text-align: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #dddddd;
}

.btn-center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-right: 25px;
}

.btn-center-content-column {
  position: absolute;
  top: 10%;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 5px;
}

.option {
  margin-right: 10px; /* Adjust spacing between options as needed */
  font-size: x-large;
  font-weight: 600;
  color: gray;
}

.description {
  font-size: medium;
  font-weight: 600;
  color: gray;
}

.image-container {
  margin-bottom: 10px;
  width: 100%;
}
</style>
