import client from '../../../config/api';

const VISUAL_DATA_CONDITIONS = ['Rubbed', 'Damaged', 'Pitted', 'Eroded'];

const buildTaskHierarchy = (tasks) => {
  const tasksMap = new Map();
  const hierarchy = [];

  // Sort tasks based on taskType and folderId
  tasks.sort((a, b) => {
    if (a.folderId && !b.folderId) {
      return 1; // Folder comes after root task
    }
    if (!a.folderId && b.folderId) {
      return -1; // Root task comes before folder
    }
    return a.taskOrder - b.taskOrder; // Sort by taskOrder
  });

  tasks.forEach((task) => {
    task.children = [];
    tasksMap.set(task._id, task);
  });

  // Traverse the tasks and assign children to their parent folders
  tasks.forEach((task) => {
    if (task.folderId) {
      const parentTask = tasksMap.get(task.folderId);
      if (parentTask) {
        parentTask.children.push(task);
      }
    } else {
      hierarchy.push(task); // Task is at the root level
    }
  });

  return hierarchy;
};

const flattenHierarchy = (hierarchy, folderLevel = 0) => {
  const flattened = [];

  hierarchy.forEach((task) => {
    task.folderLevel = folderLevel;
    flattened.push(task);

    if (task.children && task.children.length) {
      const children = flattenHierarchy(task.children, folderLevel + 1);
      flattened.push(...children);
    }
  });

  return flattened;
};

const sortTaskWithFolders = (tasks) => {
  if (tasks.lenght <= 0) return tasks;
  // To avoid mutating jobTasks and infinit loop
  const hierarchy = buildTaskHierarchy([...tasks]);
  tasks = flattenHierarchy(hierarchy);
  return tasks;
};

const isValidTimestamp = (timestamp) => {
  // Check if the input is a number and is finite
  if (!isNaN(timestamp) && isFinite(timestamp)) {
    // Convert the input to an integer
    const date = new Date(parseInt(timestamp, 10));

    // Check if the resulting date is valid
    return date.getTime() === parseInt(timestamp, 10);
  }
  return false;
};

const parseDate = (date) => {
  if (date instanceof Date) {
    const parsedDate = new Date(date);
    return parsedDate.toLocaleDateString();
  }

  if (date) {
    if (isValidTimestamp(date)) {
      return new Date(parseInt(date, 10)).toLocaleDateString();
    }

    return new Date(date).toLocaleDateString();
  }
  return '-';
};

const checkLastModifiedAtBeforeMigration = (inspection, task) => {
  if (inspection.lastModifiedAt && inspection.lastModifiedAt !== '-')
    return parseDate(inspection.lastModifiedAt);

  if (task.childConfig[0]) {
    task.childConfig[0].childConfig[0].childConfig.forEach((item) => {
      return item.inputValues.reduce((latest, input) => {
        if (input && input.bubbleModifierDate) {
          const date = new Date(input.bubbleModifierDate);
          return !latest || date > latest ? date : latest;
        }
        return latest;
      }, null);
    });
  }

  return '-';
};

const parseLengthAndDiameter = (inspection, maxNumberOfMeasurments, task) => {
  const values = [];
  const decimalPlaces = inspection.decimalPlaces || 0;
  let hasMeasurements = false;
  for (let i = 0; i < maxNumberOfMeasurments; i++) {
    const m = inspection.measurements[i];
    if (
      !m ||
      isNaN(m.value) ||
      m.value === null ||
      m.value === undefined ||
      m.value === ''
    ) {
      values.push({
        value: '-',
        name: `Value${i + 1}`,
      });
    } else {
      hasMeasurements = true;
      const measurement = parseFloat(m.value);
      let diff = measurement - parseFloat(inspection.expectedValue);
      if (diff > 0) {
        diff = Math.max(0, diff - parseFloat(inspection.positiveTolerance));
      } else if (diff < 0) {
        diff = Math.min(
          0,
          diff + Math.abs(parseFloat(inspection.negativeTolerance))
        );
      }

      const value = `${m.value}`;
      if (parseFloat(diff.toFixed(decimalPlaces)) !== 0.0) {
        values.push({
          value,
          diff: `(${diff.toFixed(decimalPlaces)})`,
          name: `Value${i + 1}`,
        });
      } else {
        values.push({
          value,
          name: `Value${i + 1}`,
        });
      }
    }
  }
  const lastModifiedAt = checkLastModifiedAtBeforeMigration(inspection, task);
  const lengthData = {
    label: inspection.number,
    values,
    expectedValue: inspection.expectedValue,
    tolerance1: `+${inspection.positiveTolerance}`,
    tolerance2: `-${inspection.negativeTolerance}`,
    unit: inspection.unit,
    lastModifiedBy:
      inspection.lastModifiedBy && hasMeasurements
        ? inspection.lastModifiedBy
        : '-',
    lastModifiedAt: hasMeasurements ? lastModifiedAt : '-',
  };

  return lengthData;
};

const parseRunOutData = (inspection, maxNumberOfMeasurments, task) => {
  const values = [];
  const decimalPlaces = inspection.decimalPlaces || 0;
  const incrementValue = Math.floor(360 / maxNumberOfMeasurments);
  let hasMeasurements = false;
  if (inspection.multipleMeasurements) {
    for (let i = 0; i < maxNumberOfMeasurments; i++) {
      const m = inspection.measurements[i];
      if (!m) {
        values.push({
          value: '-',
          name: `${incrementValue * i} Degrees`,
        });
      } else {
        const measurementValue = m.value;
        if (
          isNaN(measurementValue) ||
          measurementValue === null ||
          measurementValue === undefined ||
          measurementValue === ''
        ) {
          values.push({
            value: '-',
            name: `${incrementValue * i} Degrees`,
          });
        } else {
          hasMeasurements = true;
          const diff =
            parseFloat(measurementValue) - parseFloat(inspection.tolerance);

          if (diff > 0.0) {
            values.push({
              value: `${measurementValue}`,
              diff: `(${diff.toFixed(decimalPlaces)})`,
              name: `${incrementValue * i} Degrees`,
            });
          } else {
            values.push({
              value: `${measurementValue}`,
              name: `${incrementValue * i} Degrees`,
            });
          }
        }
      }
    }
  } else {
    const tirValue = inspection.measurements[0].value;
    if (
      isNaN(tirValue) ||
      tirValue === null ||
      tirValue === undefined ||
      tirValue === ''
    ) {
      values.push({
        value: '-',
        name: 'TIR',
      });
      values.push({
        value: '-°',
        name: 'Position',
      });
    } else {
      hasMeasurements = true;
      const measurement = parseFloat(tirValue);
      const diff = parseFloat(tirValue) - parseFloat(inspection.tolerance);
      const value = `${measurement}`;
      if (diff > 0.0) {
        values.push({
          value: `${tirValue}`,
          diff: `(${diff.toFixed(decimalPlaces)})`,
          name: 'TIR',
        });
      } else {
        values.push({
          value,
          name: 'TIR',
        });
      }

      if (!inspection.measurements[1]) {
        values.push({
          value: '-°',
          name: 'Position',
        });
      } else {
        values.push({
          value: inspection.measurements[1].value
            ? `${parseFloat(inspection.measurements[1].value).toFixed(0)}°`
            : '-°',
          name: 'Position',
        });
      }
    }
  }
  const lastModifiedAt = checkLastModifiedAtBeforeMigration(inspection, task);
  const runOutData = {
    label: inspection.number,
    values,
    expectedValue: inspection.tolerance,
    unit: inspection.unit,
    lastModifiedBy:
      inspection.lastModifiedBy && hasMeasurements !== '-'
        ? inspection.lastModifiedBy
        : '-',
    lastModifiedAt: hasMeasurements ? lastModifiedAt : '-',
    hasMultipleMeasurements: inspection.multipleMeasurements,
  };

  return runOutData;
};

const parseVisualData = (inspection, task) => {
  const visualData = [];
  let isOk = true;

  inspection.measurements.forEach((m, i) => {
    if (!m) return;
    const lastModifiedAt = checkLastModifiedAtBeforeMigration(inspection, task);
    const measurementValue = m.value;
    if (measurementValue && measurementValue !== 'notCompleted') {
      isOk = false;
      visualData.push({
        label: inspection.number,
        condition: VISUAL_DATA_CONDITIONS[i],
        value: measurementValue,
        lastModifiedBy:
          inspection.lastModifiedBy && lastModifiedAt
            ? inspection.lastModifiedBy
            : '-',
        lastModifiedAt,
      });
    }
  });

  const notCompleted = inspection.measurements.every((measurement) => {
    if (measurement) return measurement.value === 'notCompleted';
    return false;
  });
  if (notCompleted) {
    visualData.push({
      label: inspection.number,
      condition: '-',
      value: '-',
      lastModifiedBy: '-',
      lastModifiedAt: '-',
    });

    return visualData;
  }

  if (isOk) {
    visualData.push({
      label: inspection.number,
      condition: 'FINE',
      value: 'FINE',
      lastModifiedBy: inspection.lastModifiedBy
        ? inspection.lastModifiedBy
        : '-',
      lastModifiedAt: parseDate(inspection.lastModifiedAt),
    });
  }

  return visualData;
};

const parseGenericData = (inspection, task) => {
  const genericData = [];

  inspection.measurements.forEach((m) => {
    if (!m) return;
    const measurementValue = m.value;
    const lastModifiedAt = checkLastModifiedAtBeforeMigration(inspection, task);
    genericData.push({
      label: inspection.number,
      measurementName: inspection.measurementName
        ? inspection.measurementName
        : '-',
      value: measurementValue || '-',
      lastModifiedBy:
        inspection.lastModifiedBy && lastModifiedAt !== '-'
          ? inspection.lastModifiedBy
          : '-',
      lastModifiedAt,
    });
  });

  return genericData;
};

const checkImagesNamesForUUID = (images) => {
  const uuidPattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const toReturn = images.map((image) => {
    const isUUID = uuidPattern.test(image.name.split('.')[0]);
    return {
      ...image,
      name: isUUID ? '' : image.name,
    };
  });
  return toReturn;
};

const renameKeyPathToUrl = (array) => {
  array.forEach((obj) => {
    if (obj.path) {
      obj.src = obj.path;
      delete obj.path;
    }
  });
  return array;
};

const parseAttachmentInstructionsData = (questions) => {
  const data = [];
  questions.forEach((q) => {
    if (!q) return;
    if (q.image && q.image.length > 0) {
      q.image = checkImagesNamesForUUID(q.image);
      data.push({
        questionId: q._id,
        question: q.question,
        images: renameKeyPathToUrl(q.image),
      });
    }
  });
  return data;
};

const checkQuestionLastModifiedAtBeforeMigration = (question, task) => {
  if (question.lastModifiedAt && question.lastModifiedAt !== '-')
    return parseDate(question.lastModifiedAt);

  if (task.childConfig[0]) {
    let lastModifiedAt = '-';
    const item = task.childConfig[0].childConfig.find(
      (i) =>
        i.lastModifiedBy !== undefined &&
        (i.lastModifiedBy.modified !== undefined ||
          i.lastModifiedBy.date !== undefined)
    );

    if (!item) return '-';

    if (item.lastModifiedBy.date) {
      return item.lastModifiedBy.date;
    }

    if (item.lastModifiedBy.modified) {
      lastModifiedAt = Date.parse(item.lastModifiedBy.modified);
      lastModifiedAt = new Date(
        parseInt(lastModifiedAt, 10)
      ).toLocaleDateString();
    }
    return lastModifiedAt;
  }

  return '-';
};

const parseQuestionsData = (questions, subtabelsQuestions, task) => {
  const data = [];
  questions.sort((a, b) => parseInt(a.label, 10) - parseInt(b.label, 10));
  questions.forEach((q) => {
    if (!q) return;

    let answer = '-';
    switch (q.type) {
      case 'yesno':
        if (!q.yesOrNo || q.yesOrNo === null || q.yesOrNo === undefined) {
          answer = '-';
        } else {
          answer = q.yesOrNo === '' ? '-' : q.yesOrNo.toUpperCase();
        }

        break;

      case 'textnumber':
        answer = q.inputValue !== '' ? q.inputValue : '-';
        break;

      case 'action':
        answer = q.checked ? 'Done' : '-';
        break;

      case 'multiplechoice':
        answer = [];
        q.multipleChoices.map((mc) => {
          if (mc.checked) {
            const answerText = mc?.defaultMC || mc.text?.defaultMC || mc?.text;
            answer.push(answerText);
          }
        });
        if (answer.length === 0) {
          answer = '-';
        } else {
          answer = answer.join(', ');
        }
        break;

      case 'tablequestion':
        answer = 'See below';
        subtabelsQuestions.push({
          tableData: q.tableData,
          tableImg: q.tableImg,
          tableColumn: q.tableColumn,
          tableRow: q.tableRow,
          question: q.question,
        });
        break;

      case 'importcsv':
        subtabelsQuestions.push({
          tableData: q.tableData,
          tableImg: q.tableImg,
          tableColumn: q.tableColumn,
          tableRow: q.tableRow,
          question: q.question,
        });
        break;
      default:
        throw new Error(`Failed to parse question type '${q.type}'`);
    }
    let lastModifiedAt = '-';
    try {
      lastModifiedAt = checkQuestionLastModifiedAtBeforeMigration(q, task);
    } catch (error) {
      console.error(error);
    }

    data.push({
      question: q.question,
      answer,
      attachment: q.image.length > 0 ? q.image.length : '-',
      lastModifiedBy:
        q.lastModifiedBy && lastModifiedAt !== '-' ? q.lastModifiedBy : '-',
      lastModifiedAt,
    });
  });
  return data;
};

const adaptDiagramForReport = (task, bubbles) => {
  if (task.taskType === 'diagram') {
    if (!task.diagram) {
      task.diagram = {};
    }

    task.diagram.annotations = [];
    task.diagram.inspections = [];

    task.lengthData = [];
    task.diameterData = [];
    task.runoutData = [];
    task.visualData = [];
    task.genericData = [];
    task.attachmentData = [];

    if (bubbles) {
      let maxLength = 0;
      let maxDiameter = 0;
      let maxRunout = 0;
      bubbles.sort((a, b) => a.number - b.number);
      bubbles.forEach((bubble) => {
        switch (bubble.type) {
          case 'length':
            if (bubble.measurementCount > maxLength) {
              maxLength = bubble.measurementCount;
            }
            break;

          case 'diameter':
            if (bubble.measurementCount > maxDiameter) {
              maxDiameter = bubble.measurementCount;
            }
            break;

          case 'runout':
            if (bubble.measurementCount > maxRunout) {
              maxRunout = bubble.measurementCount;
            }
            break;
          default:
            break;
        }
      });
      bubbles.forEach((bubble) => {
        if (['text', 'arrow', 'line'].includes(bubble.type)) {
          task.diagram.annotations.push(bubble);
        } else {
          task.diagram.inspections.push(bubble);
          switch (bubble.type) {
            case 'length':
              task.lengthData.push(
                parseLengthAndDiameter(bubble, maxLength, task)
              );
              task.lengthData.sort((a, b) => a.label - b.label);
              break;

            case 'diameter':
              task.diameterData.push(
                parseLengthAndDiameter(bubble, maxDiameter, task)
              );
              task.diameterData.sort((a, b) => a.label - b.label);
              break;

            case 'runout':
              task.runoutData.push(parseRunOutData(bubble, maxRunout, task));
              task.runoutData.sort((a, b) => a.label - b.label);
              break;

            case 'visual':
              task.visualData.push(...parseVisualData(bubble, task));
              task.visualData.sort((a, b) => a.label - b.label);
              break;

            case 'generic':
              task.genericData.push(...parseGenericData(bubble, task));
              task.genericData.sort((a, b) => a.label - b.label);
              break;

            case 'freefinding':
              break;

            case 'rat':
              break;
            default:
              throw new Error(`Failed to parse bubble type ${bubble.type}`);
          }
          if (bubble.inspectorComment || bubble.images.length > 0) {
            task.attachmentData.push({
              bubbleId: bubble._id,
              label: bubble.number,
              images: bubble.images,
              comment: bubble.inspectorComment,
              isFreeFinding: bubble.type === 'freefinding',
            });
          }
        }
      });
    }
  }
};

const adaptInstructionForReport = (task, questions) => {
  task.attachmentData = [];
  if (questions) {
    questions.sort((a, b) => a.label - b.label);
    const subtabelsQuestions = [];
    task.instructionData = parseQuestionsData(
      questions,
      subtabelsQuestions,
      task
    );
    task.subtabelsQuestions = subtabelsQuestions;
  }

  task.attachmentData.push(...parseAttachmentInstructionsData(questions));
};

const processFolder = async (tasksToProcess) => {
  const tasks = [];
  const processSubFolders = [];
  tasksToProcess.forEach((task) => {
    tasks.push(task);
    if (task.taskType === 'folder') {
      processSubFolders.push(
        client
          .get(`task/job/${task.jobId}?folderId=${task._id}`)
          .then(({ data: folderTasks }) => processFolder(folderTasks, client))
      );
    }
  });

  await Promise.all(processSubFolders).then((subfoldersTasks) => {
    subfoldersTasks.forEach((subfolderTasks) => {
      tasks.push(...subfolderTasks);
    });
  });

  return tasks;
};

export default {
  GET_REPORT_VIEW: async ({ commit }, jobId) => {
    try {
      const reportView = {};
      const { data: job } = await client.get(`job/${jobId}`);
      reportView.job = job;

      const { data: rootTasks } = await client.get(`task/job/${jobId}`);

      let tasks = await processFolder(rootTasks);
      tasks = sortTaskWithFolders(tasks);
      reportView.tasks = tasks;

      const additionalData = [];
      tasks.forEach((task) => {
        if (task.taskType === 'diagram') {
          additionalData.push(
            client
              .get(`/bubbles/${task._id}`)
              .then(({ data: bubbles }) => adaptDiagramForReport(task, bubbles))
          );
        } else if (task.taskType === 'instruction') {
          additionalData.push(
            client
              .get(`/questions/${task._id}`)
              .then(({ data: questions }) =>
                adaptInstructionForReport(task, questions)
              )
          );
        }
      });
      await Promise.all(additionalData);

      commit('SET_REPORT_VIEW', reportView);
      if (job.excludedItems) {
        commit('SET_EXCLUDED_ITEMS', job.excludedItems);
      } else {
        commit('SET_EXCLUDED_ITEMS', []);
      }
    } catch (error) {
      console.error(error);
    }
  },

  UPDATE_ATTACHMENT_IMAGE: async (
    _,
    { bubbleId, imageUrl, imageName, imgDescription }
  ) => {
    try {
      const { data } = await client.post(
        `report/update-attachment-image/${bubbleId}`,
        {
          imageUrl,
          imageName,
          imgDescription,
        }
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  TOGGLE_EXCLUDE_ITEMS: async ({ commit, rootState, state }) => {
    if (state.enableExcludeItems) {
      try {
        await client.patch(`/job/${rootState.currentJob._id}`, {
          excludedItems: state.excludedItems,
        });
      } catch (error) {
        console.error(error);
      }
    }

    commit('TOGGLE_EXCLUDE_ITEMS');
  },

  TOGGLE_INTERNAL_REPORT: ({ commit }, value) => {
    commit('TOGGLE_INTERNAL_REPORT', value);
  },

  ITEM_TO_EXCLUDE: ({ commit }, { id, value }) => {
    commit('ITEM_TO_EXCLUDE', { id, value });
  },
};
