<template>
  <div class="content">
    <b-tabs v-model="activeTab" class="user-list-tab" :vertical="true">
      <b-tab-item>
        <template #header>
          <!-- <b-icon icon="account-check" /> -->
          <span>
            <b-tag type="is-primary" rounded> {{ users.length }} </b-tag>
            {{ $t('users.approved') }}</span
          >
        </template>
      </b-tab-item>
      <b-tab-item>
        <template #header>
          <!-- <b-icon icon="account-clock" /> -->
          <span>
            <b-tag type="is-primary" rounded>
              {{ usersPendingApproval.length }}
            </b-tag>
            {{ $t('users.pending_approval') }}</span
          >
        </template>
      </b-tab-item>
      <b-tab-item>
        <template #header>
          <!-- <b-icon icon="login" /> -->
          <span
            ><b-tag type="is-primary" rounded>
              {{ usersLoggedInToday.length }}
            </b-tag>
            {{ $t('users.todays_logins') }}</span
          >
        </template>
      </b-tab-item>
      <b-tab-item v-if="isCurrentUserPowerAdmin">
        <template #header>
          <!-- <b-icon icon="account-cancel" /> -->
          <span
            ><b-tag type="is-primary" rounded>
              {{ usersRejected.length }}
            </b-tag>
            {{ $t('users.rejected') }}
          </span>
        </template>
      </b-tab-item>
    </b-tabs>
    <div class="user-list">
      <template v-if="activeTab === 0">
        <p>{{ $t('users.approved_description') }}</p>
        <user-list :users="users" @selected="editUserProfile($event)" />
      </template>
      <template v-else-if="activeTab === 1">
        <p>{{ $t('users.pending_approval_description') }}</p>
        <user-list
          :users="usersPendingApproval"
          @selected="editUserProfile($event)"
        />
      </template>
      <template v-else-if="activeTab === 2">
        <p>{{ $t('users.todays_logins_description') }}</p>
        <user-list
          :users="usersLoggedInToday"
          @selected="editUserProfile($event)"
        />
      </template>
      <template v-else-if="activeTab === 3">
        <p>{{ $t('users.rejected_description') }}</p>
        <user-list :users="usersRejected" @selected="editUserProfile($event)" />
      </template>
    </div>
  </div>
</template>

<script>
import UserList from './UserList';

export default {
  name: 'users',
  props: {
    search: String,
  },
  components: {
    'user-list': UserList,
  },
  data() {
    return {
      activeTab: 0,
      isLoading: false,
      searchableFields: false,
      isSearchActive: false,
      filteredUsers: [],
    };
  },

  computed: {
    users() {
      return this.formatUserData(this.$store.state.users);
    },
    usersPendingApproval() {
      return this.formatUserData(this.$store.state.usersPendingApproval);
    },
    usersLoggedInToday() {
      return this.formatUserData(this.$store.state.usersLoggedInToday);
    },
    usersRejected() {
      return this.formatUserData(this.$store.state.usersRejected);
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    sites() {
      return this.$store.state.sites;
    },
    isCurrentUserPowerAdmin() {
      return (
        this.currentUser.role === 'admin' &&
        parseInt(this.currentUser.privil, 10) === 1
      );
    },
  },
  methods: {
    async getUsers(query) {
      this.isLoading = true;
      try {
        const userRequests = [];
        userRequests.push(this.$store.dispatch('getUsers', query));
        userRequests.push(
          this.$store.dispatch('getUsersPendingApproval', query)
        );
        userRequests.push(this.$store.dispatch('getUsersLoggedInToday', query));
        if (this.isCurrentUserPowerAdmin) {
          userRequests.push(
            await this.$store.dispatch('getUsersRejected', query)
          );
        }
        userRequests.push(this.$store.dispatch('getSites'));
        await Promise.all(userRequests);

        if (query && this.search) {
          this.isSearchActive = true;
        } else {
          this.isSearchActive = false;
        }
      } catch (err) {
        this.isLoading = false;

        console.error('error when trying to get users/sites: ', err);
      }

      this.isLoading = false;
    },

    editUserProfile(user) {
      this.$router.push({
        name: 'edit-user',
        params: { user, id: user._id },
      });
    },

    formatUserData(users) {
      return users.map((user) => {
        const siteFound = this.sites.find((site) => site._id === user.location);
        const formattedUser = { ...user };

        formattedUser.date = new Date(
          formattedUser.timestamp
        ).toLocaleDateString();

        if (parseInt(formattedUser.privil, 10) === 1)
          formattedUser.role = 'Power Admin';

        formattedUser.location = siteFound
          ? siteFound.siteName
          : formattedUser.location;

        if (user.lastLoginDate) {
          formattedUser.lastLoginDate = new Date(
            user.lastLoginDate
          ).toLocaleString();
        } else {
          formattedUser.lastLoginDate = 'NA';
        }

        return formattedUser;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
}
.user-list-tab {
  margin-top: 20px;
}
.user-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;

  p {
    background-color: #f5f5f5;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
