<template>
  <div id="dashboard">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="introContainer intro">
      <h1 class="introHeading">Welcome</h1>
      <h1 class="introHeading">{{ user ? user.fullname : '' }}</h1>
      <p class="introText">End to end solution for digital inspection.</p>
      <img
        class="introLogoPortrait"
        id="portraitTabletLogo"
        src="/img/dij-tablet-portrait.png"
        alt="logo-tablet"
      />
    </div>
    <ul class="inner-wrapper">
      <template v-for="tool in tools">
        <li
          :key="tool"
          class="toolIcons"
          v-if="tool === 'inspector' || !isMobile"
        >
          <router-link :to="'/view/' + tool" :id="tool">
            <img :src="`/img/icons/homepage/${tool}.png`" :id="`${tool}Img`" />
            <p>{{ tool }}</p>
          </router-link>
        </li>
      </template>
      <li class="divider"></li>
    </ul>
    <footer>
      <router-link class="footerLink" :to="'/view/version'"
        >Digital Inspection Jacket {{ version }}</router-link
      >
      <div v-if="showPopup" class="popup">
        New version available!
        <div class="arrow"></div>
      </div>
      <div style="margin-left: auto" class="ws-status" @click="onWsClick">
        WebSocket Status: {{ wsStatus }}
      </div>
    </footer>
  </div>
</template>

<script>
import versionData from '../../../version_data.json';
import { GET_WEBSOCKET_STATUS } from '../../store/websocket/getters/getterTypes';
import WebSocketMixin from '../../mixins/WebSocketMixin';

export default {
  data() {
    return {
      isLoading: false,
      version: '',
      showPopup: false,
    };
  },
  mixins: [WebSocketMixin],
  computed: {
    // get user tools according to its role
    user() {
      return this.$store.state.currentUser;
    },
    tools() {
      return this.$store.state.currentUserAllowedTools;
    },
    isMobile() {
      if (
        typeof window.orientation !== 'undefined' ||
        navigator.userAgent.indexOf('IEMobile') !== -1
      ) {
        return true;
      }
      return false;
    },
    wsStatus() {
      return this.$store.getters[GET_WEBSOCKET_STATUS];
    },
  },
  methods: {
    onWsClick() {
      this.sendMessage('taskJoin', 'Test data');
    },
    async fetchVersion() {
      try {
        const versionDataObject = versionData;
        this.version = versionDataObject[0].version;
        this.checkPopupVisibility(versionDataObject[0].date);
      } catch (error) {
        console.error('Error fetching version:', error);
      }
    },
    checkPopupVisibility(releaseDateStr) {
      const releaseDate = new Date(releaseDateStr);
      const currentDate = new Date();
      const timeDifference = currentDate - releaseDate;
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      if (daysDifference <= 5) {
        this.showPopup = true;
      }
    },
  },
  beforeMount() {
    this.isLoading = true;
  },
  async mounted() {
    await this.fetchVersion();
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  list-style: none;
}
.inner-wrapper li a {
  position: absolute;
  height: 210px;
  width: 180px;
  color: #666 !important;
  text-decoration: none;
  text-align: center;
}
.inner-wrapper li a img {
  margin-top: 60px;
  width: 80px;
}
.inner-wrapper li a p {
  margin-top: auto;
  text-transform: capitalize;
  font-weight: bold;
}
.inner-wrapper li a:hover {
  background-color: #afafaf;
  color: white !important;
}
.inner-wrapper li a:hover > img {
  filter: brightness(200%);
}
.inner-wrapper {
  position: relative;
  height: calc(100% - 250px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  color: #666;
  top: 50px;
}
.divider {
  width: 0;
  margin: 0 0 40px 0;
  border: none;
  border-right: 1px solid #666;
}
.divider:last-child {
  border: none;
}
a#report img {
  margin-top: 50px;
}
footer {
  position: fixed;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  color: #666;
  width: 100%;
  display: flex;
  align-items: center;
}
footer .footerLink {
  margin-left: 10px;
}
footer .footerLink:hover {
  color: black;
}
.divider {
  display: none;
}
tr td:first-child {
  font-weight: bold;
}
table {
  margin: 0 auto;
}
.toolIcons {
  background-color: #f1f1f1;
  margin: 10px !important;
  height: 210px;
  width: 180px;
  -webkit-box-shadow: 10px 10px 11px -7px rgba(102, 102, 102);
  -moz-box-shadow: 10px 10px 11px -7px rgba(102, 102, 102);
  box-shadow: 10px 10px 11px -7px rgba(102, 102, 102);
  left: 200px;
  bottom: 30%;
}
.introContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20%;
  margin-left: 10%;
}
.intro {
  color: #2387aa;
  width: 30%;
  float: left;
  position: relative;
  margin: 30px auto;
  margin-left: 20px;
}
.introHeading {
  flex: 1;
  text-transform: capitalize;
  font-size: 48px;
  margin-bottom: 0px;
  width: 100%;
  font-weight: 500;
  margin-bottom: -1rem;
}
.introText {
  color: #666;
  font-size: 24px;
  font-weight: bold;
  margin-top: 4rem;
  margin-bottom: 1rem;
  flex: 1;
}
.introLogoPortrait {
  width: 30%;
  margin-top: 2%;
  flex: 1;
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
  .introContainer {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-left: 5%;
  }
  .introLogoPortrait {
    width: 40%;
    margin-top: 10%;
    flex: 1;
  }
  .introLogoLandscape {
    width: 180%;
    margin-top: 2%;
    flex: 1;
  }
  .inner-wrapper {
    display: inline-flex;
    width: 66%;
    margin: 0% 0 0% -5% !important;
  }
}
/* For general iPad layouts */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .inner-wrapper {
    display: inline-flex;
    width: 66%;
    margin: 0% 0 0% -5% !important;
  }
  .introContainer {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-left: 5%;
  }
  /* For portrait layouts only */
  .introLogoPortrait {
    width: 80%;
    margin-top: 10%;
    flex: 1;
  }
}
.headerTool {
  padding: 13px 32px !important;
  color: #c0c0c0 !important;
  border: 3px solid #afafaf;
}
.headerTool:hover {
  color: white !important;
  border-bottom: 3px solid white;
}
.inner-wrapper {
  display: inline-flex;
  float: left;
  width: 66%;
  margin: 8% 0 12% 0%;
}
.header-inner-text {
  padding-top: 0% !important;
  padding-left: 60px !important;
}
.header-inner-logo {
  width: 80px !important;
  margin-left: 5px;
  margin-top: -5px;
}
.popup {
  position: absolute;
  top: -40px;
  left: 10px;
  background-color: #774195;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  animation: popupAnimation 1s ease-in-out infinite alternate;
}

.popup .arrow {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #774195;
}

@keyframes popupAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.ws-status {
  margin-left: auto;
  margin-right: 20px;
}
</style>
