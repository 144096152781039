import * as xlsx from 'xlsx';
import { v4 } from 'uuid';

const uuidv4 = v4;

export default {
  methods: {
    exportRepairAssessment() {
      const componentType = this.activeTab ? this.activeTab : 0;
      const workSheetJson = [];
      const minorHeader = {
        header: [
          'Part Name',
          'Part Number',
          'Quantity',
          'Reuse',
          'Repair',
          'Replace',
          'Comments',
        ],
      };
      const majorHeader = {
        header: [
          'Level',
          'Assembly',
          'Item Number',
          'Part Name',
          'Part Number',
          'Quantity',
          'Visual',
          'Dim',
          'NDT',
          'Reuse',
          'Repair',
          'Replace',
          'Missing',
          'Received',
          'Summary',
          'Repair Scope',
          'Estimated Hours',
          'Eng. Review',
          'Client Rejected',
          'QC',
        ],
      };
      const { repairAssessment } = this.job;

      repairAssessment.forEach((row) => {
        if (componentType === 1) {
          if (row.isMinor) {
            workSheetJson.push({
              'Part Name': row.partName,
              'Part Number': row.partNumber,
              Quantity: row.quantity,
              Reuse: row.reuse,
              Repair: row.repair,
              Replace: row.replace,
              Comments: row.comments,
            });
          }
        } else if (!row.isMinor) {
          workSheetJson.push({
            Level: row.level,
            Assembly: row.assembly,
            'Item Number': row.itemNumber,
            'Part Name': row.partName,
            'Part Number': row.partNumber,
            Quantity: row.quantity,
            Visual: row.visual,
            Dim: row.dim,
            NDT: row.ndt,
            Reuse: row.reuse,
            Repair: row.repair,
            Replace: row.replace,
            Missing: row.missing,
            Received: row.received,
            Summary: row.summary,
            'Repair Scope': row.repairScope,
            'Estimated Hours': row.estimatedLabor,
            'Eng. Review': row.reviewed,
            'Client Rejected': row.clientReject,
            QC: this.getQuality(row),
          });
        }
      });

      const workbook = xlsx.utils.book_new();
      const sheetHeader = componentType === 1 ? minorHeader : majorHeader;

      const sheet = xlsx.utils.json_to_sheet(workSheetJson, sheetHeader);
      xlsx.utils.book_append_sheet(workbook, sheet, 'Repair Assessement');

      const now = new Date();
      const datetimeString = `${now.getFullYear()}${now.getMonth()}${now.getDate()}${now.getHours()}${now.getMinutes()}`;
      let fileName = 'Major Components';
      if (componentType === 1) fileName = 'Minor Components';
      xlsx.writeFile(
        workbook,
        `${fileName} - ${this.job.title} - ${datetimeString}.xlsx`
      );
    },
    getQuality(item) {
      if (this.shouldShowQuality(item)) {
        if (this.isQualityYellow(item)) {
          return 'yellow';
        }
        if (this.isQualityGreen(item)) {
          return 'green';
        }
        return 'red';
      }
      return 'N/A';
    },
    shouldShowQuality(item) {
      return (
        (item.reviewed &&
          item.reuse + item.repair + item.replace + item.missing !== 0) ||
        item.qualityControlOverride
      );
    },
    handleRepairAssessmentImportAdd(event) {
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = (onloadEvent) => {
        const importedRepairAssessment = this.readRepairAssessmentFile(
          onloadEvent.target.result
        );

        if (!importedRepairAssessment) {
          return;
        }

        const importedRows = [];
        importedRepairAssessment.forEach((row) => {
          importedRows.push(row);
        });

        this.$store
          .dispatch('addRepairAssessmentRow', {
            job: this.job,
            rows: importedRows,
            tool: this.tool,
            replace: true,
            replaceMinor: this.activeTab !== 0,
          })
          .then((createdRows) => {
            this.filteredRepairAssessment = [];
            if (!Array.isArray(createdRows)) {
              createdRows = [createdRows];
            }
            createdRows.forEach((row) => {
              this.filteredRepairAssessment.push(row);
            });
            this.refresh();
          });
      };
      fileReader.readAsBinaryString(file);
      // Clear the selected file so the user can reload the same file
      event.target.value = null;
    },
    readRepairAssessmentFile(binary) {
      const workbook = xlsx.read(binary, {
        type: 'binary',
      });

      const workSheetJson = xlsx.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: 1,
        }
      );

      const headers = workSheetJson[0].map((header) => header.toLowerCase());
      workSheetJson.splice(0, 1);

      const repairAssessment = [];

      const componentType = this.activeTab ? this.activeTab : 0;
      if (componentType === 1) {
        const partNameLocation = headers.indexOf('part name');
        const partNumberLocation = headers.indexOf('part number');
        const quantityLocation = headers.indexOf('quantity');
        const reuseLocation = headers.indexOf('reuse');
        const repairLocation = headers.indexOf('repair');
        const replaceLocation = headers.indexOf('replace');
        const commentsLocation = headers.indexOf('comments');
        let validHeader = false;
        validHeader =
          partNameLocation !== -1 &&
          partNumberLocation !== -1 &&
          quantityLocation !== -1 &&
          reuseLocation !== -1 &&
          repairLocation !== -1 &&
          replaceLocation !== -1 &&
          commentsLocation !== -1;

        if (!validHeader) {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$t('repair_assessment_excel_import_failed'),
            type: 'is-danger',
          });
          return;
        }

        workSheetJson.forEach((row) => {
          const emptyRow =
            !row[partNameLocation] &&
            !row[partNumberLocation] &&
            !row[quantityLocation] &&
            !row[reuseLocation] &&
            !row[repairLocation] &&
            !row[replaceLocation] &&
            !row[commentsLocation];
          if (!emptyRow) {
            repairAssessment.push({
              isMinor: true,
              id: uuidv4(),
              partName: row[partNameLocation],
              partNumber: row[partNumberLocation],
              quantity: isNaN(row[quantityLocation])
                ? 0
                : row[quantityLocation],
              reuse: isNaN(row[reuseLocation]) ? 0 : row[reuseLocation],
              repair: isNaN(row[repairLocation]) ? 0 : row[repairLocation],
              replace: isNaN(row[replaceLocation]) ? 0 : row[replaceLocation],
              comments: row[commentsLocation],
            });
          }
        });
      } else {
        const levelLocation = headers.indexOf('level');
        const assemblyLocation = headers.indexOf('assembly');
        const itemNumberLocation = headers.indexOf('item number');
        const partNameLocation = headers.indexOf('part name');
        const partNumberLocation = headers.indexOf('part number');
        const quantityLocation = headers.indexOf('quantity');
        const visualLocation = headers.indexOf('visual');
        const dimLocation = headers.indexOf('dim');
        const ndtLocation = headers.indexOf('ndt');
        const reuseLocation = headers.indexOf('reuse');
        const repairLocation = headers.indexOf('repair');
        const replaceLocation = headers.indexOf('replace');
        const missingLocation = headers.indexOf('missing');
        const receivedLocation = headers.indexOf('received');
        const summaryLocation = headers.indexOf('summary');
        const repairScopeLocation = headers.indexOf('repair scope');
        const estimatedHoursLocation = headers.indexOf('estimated hours');
        const engReviewLocation = headers.indexOf('eng. review');
        const clientRejectedLocation = headers.indexOf('client rejected');
        const qcLocation = headers.indexOf('qc');

        let validHeader = false;
        if (this.tool.includes('editor')) {
          validHeader =
            levelLocation !== -1 &&
            assemblyLocation !== -1 &&
            itemNumberLocation !== -1 &&
            partNameLocation !== -1 &&
            quantityLocation !== -1;
        } else {
          validHeader =
            levelLocation !== -1 &&
            assemblyLocation !== -1 &&
            itemNumberLocation !== -1 &&
            partNameLocation !== -1 &&
            partNumberLocation !== -1 &&
            quantityLocation !== -1 &&
            visualLocation !== -1 &&
            dimLocation !== -1 &&
            ndtLocation !== -1 &&
            reuseLocation !== -1 &&
            repairLocation !== -1 &&
            replaceLocation !== -1 &&
            missingLocation !== -1 &&
            receivedLocation !== -1 &&
            summaryLocation !== -1 &&
            repairScopeLocation !== -1 &&
            estimatedHoursLocation !== -1 &&
            engReviewLocation !== -1 &&
            clientRejectedLocation !== -1 &&
            qcLocation !== -1;
        }

        if (!validHeader) {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$t('repair_assessment_excel_import_failed'),
            type: 'is-danger',
          });
          return;
        }

        if (this.tool.includes('editor')) {
          workSheetJson.forEach((row) => {
            const emptyRow =
              !row[levelLocation] &&
              !row[assemblyLocation] &&
              !row[itemNumberLocation] &&
              !row[partNameLocation] &&
              !row[quantityLocation];
            if (!emptyRow) {
              repairAssessment.push({
                id: uuidv4(),
                level: isNaN(row[levelLocation]) ? 0 : row[levelLocation],
                assembly: row[assemblyLocation],
                itemNumber: row[itemNumberLocation],
                partName: row[partNameLocation],
                quantity: isNaN(row[quantityLocation])
                  ? 0
                  : row[quantityLocation],
              });
            }
          });
        } else {
          workSheetJson.forEach((row) => {
            const emptyRow =
              !row[levelLocation] &&
              !row[assemblyLocation] &&
              !row[itemNumberLocation] &&
              !row[partNameLocation] &&
              !row[partNumberLocation] &&
              !row[quantityLocation] &&
              !row[visualLocation] &&
              !row[dimLocation] &&
              !row[ndtLocation] &&
              !row[reuseLocation] &&
              !row[repairLocation] &&
              !row[replaceLocation] &&
              !row[missingLocation] &&
              !row[receivedLocation] &&
              !row[summaryLocation] &&
              !row[repairScopeLocation] &&
              !row[estimatedHoursLocation] &&
              !row[engReviewLocation] &&
              !row[clientRejectedLocation] &&
              !row[qcLocation];
            if (!emptyRow) {
              repairAssessment.push({
                id: uuidv4(),
                level: isNaN(row[levelLocation]) ? 0 : row[levelLocation],
                assembly: row[assemblyLocation],
                itemNumber: row[itemNumberLocation],
                partName: row[partNameLocation],
                partNumber: row[partNumberLocation],
                quantity: isNaN(row[quantityLocation])
                  ? 0
                  : row[quantityLocation],
                visual: row[visualLocation],
                dim: row[dimLocation],
                ndt: row[ndtLocation],
                reuse: row[reuseLocation],
                repair: row[repairLocation],
                replace: row[replaceLocation],
                missing: row[missingLocation],
                received: row[receivedLocation],
                summary: row[summaryLocation],
                repairScope: row[repairScopeLocation],
                estimatedLabor: row[estimatedHoursLocation],
                reviewed: row[engReviewLocation],
                clientReject: row[clientRejectedLocation],
                qualityControlOverride: row[qcLocation],
              });
            }
          });
        }
      }
      return repairAssessment;
    },
    isQualityGreen(item) {
      return (
        item.qualityControlOverride ||
        item.reuse === item.quantity ||
        (item.replace > 0 && item.received + item.reuse >= item.quantity)
      );
    },

    isQualityYellow(item) {
      return (
        !item.qualityControlOverride &&
        (!item.quantity ||
          item.quantity === 0 ||
          item.repair > 0 ||
          item.missing > 0 ||
          item.clientReject)
      );
    },
  },
};
