<template>
  <div v-if="showToolbar" class="dij-diagrambuilder-toolbar">
    <div
      v-for="(tool, index) in tools"
      :key="index"
      :title="$t(tool.title)"
      :class="{
        [tool.class]: true,
        disabled: !isImageSelected,
        'dij-tool-active': mode === tool.mode,
        separator: tool.hasSeparatorBelow,
      }"
      class="tool-entry tool-font-size"
      @click="toolClick(tool)"
    >
      <div class="position-image-tool" v-if="tool.mode === 'position-image'">
        <img
          src="/img/position-image/pos_1.png"
          @click.stop="
            toolClick({ ...tool, type: '/img/position-image/pos_1.png' })
          "
        />
        <img
          src="/img/position-image/pos_2.png"
          @click.stop="
            toolClick({ ...tool, type: '/img/position-image/pos_2.png' })
          "
        />
        <img
          src="/img/position-image/pos_3.png"
          @click.stop="
            toolClick({ ...tool, type: '/img/position-image/pos_3.png' })
          "
        />
        <img
          src="/img/position-image/pos_4.png"
          @click.stop="
            toolClick({ ...tool, type: '/img/position-image/pos_4.png' })
          "
        />
      </div>
      <b-icon
        v-if="tool.icon"
        :icon="tool.icon"
        :type="mode === tool.mode ? 'is-info' : ''"
      ></b-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isImageSelected: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    showToolbar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tools: [
        {
          title: 'selection',
          enableMode: 'enableSelectMode',
          mode: 'select',
          icon: 'cursor-default',
          hasSeparatorBelow: true,
        },
        {
          title: 'diameter',
          enableMode: 'enableDiameterMode',
          class: 'dij-diagrambuilder-toolbar-diameter',
          mode: 'diameter',
        },
        {
          title: 'runout',
          class: 'dij-diagrambuilder-toolbar-runout',
          enableMode: 'enableRunoutMode',
          mode: 'runout',
        },
        {
          title: 'linearlength',
          class: 'dij-diagrambuilder-toolbar-linearlength',
          enableMode: 'enableLinearLengthMode',
          mode: 'linearLength',
        },
        {
          title: 'alignedlength',
          class: 'dij-diagrambuilder-toolbar-alignedlength',
          enableMode: 'enableAlignedLengthMode',
          mode: 'alignedLength',
        },
        {
          title: 'visual',
          class: 'dij-diagrambuilder-toolbar-visual',
          enableMode: 'enableVisualMode',
          mode: 'visual',
        },
        {
          title: 'generic',
          class: 'dij-diagrambuilder-toolbar-generic',
          enableMode: 'enableGenericMode',
          mode: 'generic',
          hasSeparatorBelow: true,
        },
        {
          title: 'position-image',
          class: 'dij-diagrambuilder-toolbar-position-image',
          enableMode: 'enablePositionImage',
          mode: 'position-image',
        },
        {
          title: 'table',
          class: 'dij-diagrambuilder-toolbar-table',
          enableMode: 'enableTableMode',
          mode: 'table',
        },
        {
          title: 'text',
          class: 'dij-diagrambuilder-toolbar-text',
          enableMode: 'enableTextMode',
          mode: 'text',
        },
        {
          title: 'arrow',
          class: 'dij-diagrambuilder-toolbar-arrow',
          enableMode: 'enableArrowMode',
          mode: 'arrow',
        },
        {
          title: 'line',
          class: 'dij-diagrambuilder-toolbar-line',
          enableMode: 'enableLineMode',
          mode: 'line',
        },
        {
          title: 'replace',
          enableMode: 'replaceImage',
          mode: 'replace',
          icon: 'sync',
        },
      ],
    };
  },
  methods: {
    toolClick(tool) {
      if (tool.mode === 'position-image') {
        this.$emit('enableMode', tool);
      } else {
        this.$emit('enableMode', tool.enableMode);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.separator {
  border-bottom: 1px solid black;
}
.tool-font-size {
  font-size: 27px;
}
.tool-entry {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dij-diagrambuilder-toolbar {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 70px;

  & > div {
    width: 100%;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
    background-color: rgb(245, 245, 245);
    cursor: pointer;

    :hover,
    &.dij-tool-active {
      background-color: white;
    }

    &.disabled {
      opacity: 0.5;
      cursor: unset;
    }
  }

  &-selection {
    background-image: url('/img/icons/selection_inactive.png');
    &.dij-tool-active {
      background-image: url('/img/icons/selection_active.png');
    }
  }
  &-move {
    background-image: url('/img/icons/pan_view_inactive.png');
    &.dij-tool-active {
      background-image: url('/img/icons/pan_view_active.png');
    }
  }
  &-diameter {
    background-image: url('/img/icons/icon_diameter_inactive@2x.png');
    &.dij-tool-active {
      background-image: url('/img/icons/icon_diameter_active@2x.png');
    }
  }
  &-runout {
    background-image: url('/img/icons/icon_run-out_inactive@2x.png');
    &.dij-tool-active {
      background-image: url('/img/icons/icon_run-out_active@2x.png');
    }
  }
  &-linearlength {
    background-image: url('/img/icons/icon_linear-length_inactive@2x.png');
    &.dij-tool-active {
      background-image: url('/img/icons/icon_linear-length_active@2x.png');
    }
  }
  &-alignedlength {
    background-image: url('/img/icons/icon_aligned-length_inactive@2x.png');
    &.dij-tool-active {
      background-image: url('/img/icons/icon_aligned-length_active@2x.png');
    }
  }
  &-visual {
    background-image: url('/img/icons/icon_visual_inactive.png');
    &.dij-tool-active {
      // TODO: here should be ACTIVE ICON
      background-image: url('/img/icons/icon_visual_inactive.png');
    }
  }
  &-visual {
    background-image: url('/img/icons/icon_visual_inactive.png');
    &.dij-tool-active {
      background-image: url('/img/icons/visual_inspection_active.png');
    }
  }
  &-generic {
    background-image: url('/img/icons/icon_generic.png');
    &.dij-tool-active {
      background-image: url('/img/icons/icon_generic_active.png');
    }
  }
  &-position-image {
    background-image: url('/img/position-image/pos_1');
    background-size: 60px !important;
  }
  &-text {
    background-image: url('/img/icons/icon_text_inactive@2x.png');
    &.dij-tool-active {
      background-image: url('/img/icons/icon_text_inactive@2x.png');
    }
  }
  &-arrow {
    background-image: url('/img/icons/icon_arrow_inactive@2x.png');
    &.dij-tool-active {
      background-image: url('/img/icons/icon_arrow_active@2x.png');
    }
  }
  &-line {
    background-image: url('/img/icons/icon_line_inactive.png');
    &.dij-tool-active {
      background-image: url('/img/icons/icon_line_active.png');
    }
  }
  &-table {
    background-image: url('/img/table-cells.png');
    &.dij-tool-active {
      background-image: url('/img/table-cells.png');
    }
  }
  &-replace {
    background-image: url('/img/icons/icon_visual_inactive.png');
  }
  & > div.dij-diagrambuilder-toolbar-separator {
    height: 1px;
    border-top: 1px solid black;
  }

  .position-image-tool {
    display: none;
    flex-direction: column;

    width: 100px;
    z-index: 1;
    position: absolute;
    left: 70px;
    background-color: #f5f5f5;
  }
  .dij-diagrambuilder-toolbar-position-image:hover .position-image-tool {
    display: flex; /* Display when hovering */
    background-color: #f5f5f5 !important;
  }
}
</style>
