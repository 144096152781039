import isEqual from 'lodash/isEqual';
import PouchDB from 'pouchdb';
import { v4 } from 'uuid';
import client from '../config/api';
import oilApi from '../config/oilApi';
import DIJVueConfigurations from '../helpers/DIJVueConfigurations';
import calculations from './calculations';

import { JOBS_GET_FOLDERS } from './jobs/actions/actionTypes';

const uuidv4 = v4;

const actions = {
  ...calculations,

  login: async ({}) => {
    await client.post('/users/current/login');
  },

  /**
   * Get the user profile and allowed tools from the accessToken
   */
  getCurrentUserProfile: async ({ commit }) => {
    // Get current user profile
    return client.get('/users/current').then(({ data: user }) => {
      if (user.surrogate) {
        commit('SET_SURROGATE', user.surrogate);
        delete user.surrogate;
      } else {
        commit('CLEAR_SURROGATE');
      }

      commit('SET_CURRENT_USER', user);

      // Get all roles
      return client.get('/userRoles').then(({ data: roles }) => {
        // Get all permission from role
        const role = roles.find((userRole) => userRole.rolename === user.role);
        const tools = role?.toollist || [];
        commit('SET_CURRENT_USER_ALLOWED_TOOLS', tools);
      });
    });
  },

  /**
   * Get the user profile from the given email
   */
  getUserProfile: async ({ commit }, email) => {
    const result = await client.get(`/users/${email}`);
    commit('SET_USER', result.data);
    return result.data;
  },

  getUserRoles: async ({ commit }, query) => {
    const result = await client.get('/userRoles', {
      params: {
        ...query,
      },
    });

    commit('SET_USER_ROLES', result.data);
  },

  surrogate: async ({ dispatch }, user) => {
    await client.post(`/users/surrogate/${user._id}`);

    // Get the information of the surrogated user
    dispatch('getCurrentUserProfile');
  },

  clearSurrogate: async ({ dispatch }) => {
    await client.post('/users/clearSurrogate');

    // Get the information of the surrogate user
    dispatch('getCurrentUserProfile');
  },

  createUserRole: async ({ commit }, role) => {
    try {
      const result = await client.post('/userRoles', role);

      commit('CREATE_USER_ROLE', result.data);
    } catch (err) {
      throw new Error(err);
    }
  },
  updateUserRole: async ({ state, commit }, updatedRole) => {
    try {
      await client.put(`/userRoles/${updatedRole._id}`, updatedRole);
      const index = state.userRoles.findIndex(
        (roleToFind) => roleToFind._id === updatedRole._id
      );

      commit('UPDATE_USER_ROLE', { index, role: updatedRole });
    } catch (err) {
      throw new Error(err);
    }
  },

  deleteUserRole: async ({ state, commit }, roleToDelete) => {
    try {
      await client.delete(`/userRoles/${roleToDelete._id}`, roleToDelete);
      const index = state.userRoles.findIndex(
        (roleToFind) => roleToFind._id === roleToDelete._id
      );

      commit('DELETE_USER_ROLE', index);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Get the list of users to show on admin's page
   */
  getUsers: async ({ commit }, query) => {
    const { data } = await client.get('/users', {
      params: {
        ...query,
      },
    });
    commit('SET_USERS', data);

    return data;
  },

  /**
   * Get the list of users to show on admin's page
   */
  getUsersPendingApproval: async ({ commit }, query) => {
    const queryWithPendingApproval = { pendingApproval: true, ...query };
    const result = await client.get('/users', {
      params: {
        ...queryWithPendingApproval,
      },
    });
    commit('SET_USERS_PENDING_APPROVAL', result.data);
  },

  getUsersLoggedInToday: async ({ commit }, query) => {
    const now = new Date();
    const lastMidnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const queryWithLoggedInToday = {
      loggedInAfter: lastMidnight.getTime(),
      ...query,
    };
    const result = await client.get('/users', {
      params: {
        ...queryWithLoggedInToday,
      },
    });
    commit('SET_USERS_LOGGED_IN_TODAY', result.data);
  },

  getUsersRejected: async ({ commit }, query) => {
    const queryWithRejected = { rejected: true, ...query };
    const result = await client.get('/users', {
      params: {
        ...queryWithRejected,
      },
    });
    commit('SET_USERS_REJECTED', result.data);
  },

  /**
   * Get the job from the api and sets the currentJob state variable
   */
  getJob: async ({ commit }, id) => {
    const result = await client.get(`/job/${id}`);
    commit('SET_CURRENT_JOB', result.data);
    if (result.data.excludedItems) {
      result.data.excludedItems.forEach((item) => {
        commit(
          'ReportsModule/ITEM_TO_EXCLUDE',
          { id: item, value: true },
          { root: true }
        );
      });
    }
  },

  getDeletedJob: async ({ commit }, id) => {
    const result = await client.get(`/job/${id}`, {
      params: { deleted: true },
    });
    commit('SET_CURRENT_JOB', result.data);
    return result.data;
  },

  getJobTasks: async ({ dispatch, commit }, data) => {
    let tasks;
    if (data.allTasks) {
      // Get all items in the root folder
      const returnedTasks = await client.get(`/task/job/${data.jobId}`);
      const rootItems = returnedTasks.data;

      // Get all folders
      const allJobFolders = await dispatch(JOBS_GET_FOLDERS, data.jobId);
      // Create one request per folder
      const requests = [];
      allJobFolders.data.forEach((folder) => {
        requests.push(
          client.get(`/task/job/${data.jobId}`, {
            params: { folderId: folder._id },
          })
        );
      });

      tasks = [];
      const folderItems = await Promise.all(requests);

      // Add all folder items to the list
      folderItems.forEach((folderItem) => tasks.push(...folderItem.data));

      // Add all root items to the list
      rootItems.forEach((rootItem) => tasks.push(rootItem));
    } else {
      let options;
      if (data.folderId) {
        options = {
          params: {
            folderId: data.folderId,
          },
        };
      }

      const result = await client.get(`/task/job/${data.jobId}`, options);
      tasks = result.data;
    }

    if (data.return) {
      return tasks;
    }

    commit('SET_JOB_TASKS', tasks);
    commit('SET_TASKS', tasks);
  },

  getJobDeletedTasks: async ({ commit }, { jobId }) => {
    // Get all items in the root folder
    const returnedTasks = await client.get(`/task/job/${jobId}`, {
      params: { allTasks: true, deleted: true },
    });
    const tasks = returnedTasks.data.sort((a, b) => {
      if (a.deletedAt > b.deletedAt) {
        return -1;
      }
      if (a.deletedAt < b.deleteAt) {
        return 1;
      }

      return 0;
    });

    commit('SET_DELETED_TASKS', tasks);

    return tasks;
  },

  /**
   * Get the task from the api and sets the task state variable
   */
  getTask: async ({ commit }, data) => {
    console.log('ENTERED TASKKKK');

    let id = data;
    let notUpdateState = false;
    if (typeof data === 'object') {
      id = data.id;
      notUpdateState = true;
    }
    const result = await client.get(`/task/${id}`);

    if (notUpdateState) {
      return result.data;
    }
    commit('SET_TASK_OBJECT', result.data);
  },

  getDeletedTask: async ({ commit }, id) => {
    const result = await client.get(`/task/${id}`, {
      params: { deleted: true },
    });

    commit('SET_TASK_OBJECT', result.data);
    return result.data;
  },

  getSites: async ({ commit }, query) => {
    const result = await client.get('/sites', {
      params: {
        ...query,
      },
    });
    commit('SET_SITES', result.data);
  },

  createSite: async ({ commit }, site) => {
    try {
      const result = await client.post('/sites', site);

      commit('CREATE_SITE', result.data);
    } catch (err) {
      throw new Error(err);
    }
  },

  updateSite: async ({ state, commit }, updatedSite) => {
    try {
      await client.put(`/sites/${updatedSite._id}`, updatedSite);
      const index = state.sites.findIndex(
        (siteToFind) => siteToFind._id === updatedSite._id
      );

      commit('UPDATE_SITE', { index, site: updatedSite });
    } catch (err) {
      throw new Error(err);
    }
  },

  deleteSite: async ({ state, commit }, siteToDelete) => {
    try {
      await client.delete(`/sites/${siteToDelete._id}`, siteToDelete);
      const index = state.sites.findIndex(
        (roleToFind) => roleToFind._id === siteToDelete._id
      );

      commit('DELETE_USER_ROLE', index);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Set the current job to empty
   */
  clearCurrentJob: ({ commit }) => {
    commit('SET_CURRENT_JOB', {});
  },

  countJobs: async ({ commit }, { type, locations }) => {
    const config = {
      params: {
        type,
        locations,
      },
    };
    const result = await client.get('/jobcount', config);
    commit('SET_TOTAL_JOBS', result.data.count);
  },
  /**
   * Get jobs from the api with a query
   */
  getJobs: async ({ commit, state }, query) => {
    // How many records are we expecting
    let { expectedRecordsCount } = query;
    delete query.expectedRecordsCount;

    const isNewQuery = !isEqual(query, state.jobsQuery);

    let jobs;
    let lastKey;

    // If the query is new, drop all previous records and prepare to receive new ones
    // If not, merge the new records with the old ones
    if (isNewQuery) {
      jobs = [];
      lastKey = null;
      expectedRecordsCount = null;
    } else {
      jobs = [...state.jobs];
      lastKey = state.jobsLastKey;
    }

    if (!expectedRecordsCount) {
      expectedRecordsCount = jobs.length + 50;
    }

    let hasMoreData = !!lastKey;
    // If there's already enought records to cover the expectedRecordsCount
    // or if there's no more data, return the existing
    if (jobs.length >= expectedRecordsCount || (!isNewQuery && !hasMoreData)) {
      return { isNewQuery, jobs, hasMoreData };
    }

    try {
      commit('SET_WAITING_FOR_READ', true);

      // Get data until we have more records than expectedRecordsCount
      // or if there's no more data
      do {
        if (lastKey) {
          query.lastKey = lastKey;
        }
        const config = {
          params: {
            query,
          },
        };
        // eslint-disable-next-line no-await-in-loop
        const result = await client.get('/job', config);
        lastKey = result.data.lastKey;
        jobs.push(...result.data.jobs);
      } while (lastKey && expectedRecordsCount > jobs.length);

      delete query.lastKey;

      commit('SET_JOBS', jobs);
      commit('SET_JOBS_LASTKEY', lastKey);
      commit('SET_JOBS_QUERY', query);

      hasMoreData = !!lastKey;
      return { isNewQuery, jobs, hasMoreData };
    } finally {
      commit('SET_WAITING_FOR_READ', false);
    }
  },

  getDeletedJobs: async ({ commit, state }, query) => {
    // How many records are we expecting
    let { expectedRecordsCount } = query;
    delete query.expectedRecordsCount;

    query.deleted = true;

    const isNewQuery = !isEqual(query, state.deletedJobsQuery);

    let jobs;
    let lastKey;

    // If the query is new, drop all previous records and prepare to receive new ones
    // If not, merge the new records with the old ones
    if (isNewQuery) {
      jobs = [];
      lastKey = null;
      expectedRecordsCount = null;
    } else {
      jobs = [...state.deletedJobs];
      lastKey = state.deletedJobsLastKey;
    }

    if (!expectedRecordsCount) {
      expectedRecordsCount = jobs.length + 50;
    }

    let hasMoreData = !!lastKey;
    // If there's already enought records to cover the expectedRecordsCount
    // or if there's no more data, return the existing
    if (jobs.length >= expectedRecordsCount || (!isNewQuery && !hasMoreData)) {
      return { isNewQuery, jobs, hasMoreData };
    }

    // Get data until we have more records than expectedRecordsCount
    // or if there's no more data
    do {
      if (lastKey) {
        query.lastKey = lastKey;
      }
      const config = {
        params: {
          query,
        },
      };
      // eslint-disable-next-line no-await-in-loop
      const result = await client.get('/job', config);
      lastKey = result.data.lastKey;
      jobs.push(...result.data.jobs);
    } while (lastKey && expectedRecordsCount > jobs.length);

    delete query.lastKey;

    commit('SET_DELETED_JOBS', jobs);
    commit('SET_DELETED_JOBS_LASTKEY', lastKey);
    commit('SET_DELETED_JOBS_QUERY', query);

    hasMoreData = !!lastKey;
    return { isNewQuery, jobs, hasMoreData };
  },

  /**
   * Set tasks related to the currentJob to currentTasks
   */
  setCurrentTasks: async ({ commit }, currentJob) => {
    if (
      Object.keys(currentJob).length > 0 &&
      currentJob.childConfig[0] &&
      currentJob.childConfig[0].childConfig
    ) {
      commit('SET_TASKS_CURRENT_JOB', currentJob.childConfig[0].childConfig);
    } else {
      commit('SET_TASKS_CURRENT_JOB', []);
    }
  },

  /**
   * Updating tasks if they have been changed
   */
  updateTasksCurrentJob: async ({ commit }, updatedTasks) => {
    commit('SET_TASKS_CURRENT_JOB', updatedTasks);
  },

  patchJob: async ({ commit, state, dispatch }, { patch, tool }) => {
    patch.userLocation = state.currentUser.location;
    if (patch.partNumber && patch.partNumber === '') {
      delete patch.partNumber;
    }

    const id = patch._id;
    patch = (
      await client.patch(`job/${id}`, patch, {
        params: { tool },
      })
    ).data.Attributes;

    const arrayOfFields = [
      'workOrderName',
      'brand',
      'frameType',
      'model',
      'workOrderNumber',
      'userEnteredPartNumber',
    ];

    const needTaskUpdate = arrayOfFields.some((field) => !!patch[field]);

    if (needTaskUpdate) {
      const allTasks = await dispatch('getJobTasks', {
        jobId: patch._id,
        allTasks: true,
        return: true,
      });

      const filteredTasks = allTasks.filter(
        (task) => task.taskType === 'instruction' || task.taskType === 'diagram'
      );

      filteredTasks.forEach(async (task) => {
        const taskToUpdate = task;
        arrayOfFields.forEach((field) => {
          if (taskToUpdate[field]) taskToUpdate[field] = patch[field];
        });

        await dispatch('updateTask', taskToUpdate);
      });
    }

    commit('SET_CURRENT_JOB', patch);
    commit('UPDATE_JOB_IN_LIST', patch);
  },

  updateJobSimple: async ({ commit, state }, jobUpdated) => {
    const id = jobUpdated._id;
    jobUpdated.userLocation = state.currentUser.location;
    if (!jobUpdated.partNumber || jobUpdated.partNumber === '') {
      delete jobUpdated.partNumber;
    }

    await client.put(`job/${id}`, jobUpdated);

    commit('SET_CURRENT_JOB', jobUpdated);
    commit('UPDATE_JOB_IN_LIST', jobUpdated);

    return jobUpdated;
  },

  updateJob: async ({ dispatch }, jobUpdated) => {
    jobUpdated = await dispatch('updateJobSimple', jobUpdated);
    const allTasks = await dispatch('getJobTasks', {
      jobId: jobUpdated._id,
      allTasks: true,
      return: true,
    });

    const arrayOfFields = [
      'workOrderName',
      'brand',
      'frameType',
      'model',
      'workOrderNumber',
      'userEnteredPartNumber',
      'documentNumber',
      'revision',
    ];

    const filteredTasks = allTasks.filter(
      (task) => task.taskType === 'instruction' || task.taskType === 'diagram'
    );

    const tasksToBeUpdated = [];

    filteredTasks.forEach(async (task) => {
      const promise = new Promise((resolve) => {
        const taskToUpdate = task;
        arrayOfFields.forEach((field) => {
          if (taskToUpdate[field]) taskToUpdate[field] = jobUpdated[field];
        });

        dispatch('updateTask', taskToUpdate).then(() => resolve());
      });
      tasksToBeUpdated.push(promise);
    });

    await Promise.all(tasksToBeUpdated);

    return jobUpdated;
  },

  reassignJob: async ({ commit, state }, object) => {
    const { job, newOwner } = object;
    let jobUpdated;
    try {
      const result = await client.put(`job/${job._id}`, { newOwner });
      jobUpdated = result.data;

      // Keep job in the list if the current user has the rights to see the job
      if (
        (parseInt(state.currentUser.privil, 10) === 1 &&
          state.currentUser.role === 'admin') ||
        jobUpdated.location === state.currentUser.location
      ) {
        commit('UPDATE_JOB_IN_LIST', jobUpdated);
      } else {
        commit('REMOVE_JOB_FROM_THE_LIST', jobUpdated);
      }
    } catch (err) {
      console.error('Error when reassigning job: ', err);
      throw new Error('Error when reassigning job');
    }
  },

  reassignJobsByUser: async ({ commit }, object) => {
    const { newOwner, oldOwner } = object;
    let updatedJobs;

    try {
      const result = await client.put(`reassign/jobs/user/${oldOwner._id}`, {
        newOwner,
      });

      updatedJobs = result.data;

      updatedJobs.forEach((job) => {
        commit('SET_CURRENT_JOB', job);
        commit('UPDATE_JOB_IN_LIST', job);
      });
    } catch (err) {
      console.error('Error when reassigning jobs: ', err);
      throw new Error('Error when reassigning job');
    }
  },

  /* Getters/Setters Products List */
  getProductNameList: async ({ commit }) => {
    const result = await client.get('/getProductNameList');
    commit('SET_PRODUCT_LIST_NAME', result.data);
  },

  getOriginalOEMBrandList: async ({ commit }, productsArray) => {
    const result = await client.get('/getOriginalOEMBrandList', {
      params: {
        listId: productsArray,
      },
    });
    commit('SET_ORIGINAL_OEM_BRAND_LIST', result.data);
  },

  getFrameTypeList: async ({ commit }, originalOEMBrandArray) => {
    const result = await client.get('/getFrameTypeList', {
      params: {
        listId: originalOEMBrandArray,
      },
    });
    commit('SET_FRAME_TYPE_LIST', result.data);
  },

  getModelsList: async ({ commit }, frameTypeArray) => {
    const result = await client.get('/getModelsList', {
      params: {
        listId: frameTypeArray,
      },
    });
    commit('SET_MODEL_LIST', result.data);
  },

  configureTasks: async ({ dispatch }, data) => {
    // iterate configurations
    const tasks = data.tasks ? data.tasks : [];
    const oldTasks = [...data.tasks];
    let tasksOrderCount = 0;
    const foldersNames = [];
    for (let c = 0; c < data.tablesConfigurations.length; c++) {
      const config = data.tablesConfigurations[c];
      const folderId = uuidv4();
      let folderName = config.partLevel.name;
      let increment = 1;
      while (foldersNames.includes(folderName)) {
        folderName = `${config.partLevel.name}_${increment}`;
        increment++;
      }
      foldersNames.push(folderName);
      const folder = {
        _id: folderId,
        taskOrder: tasksOrderCount,
        jobId: data.jobId,
        taskType: 'folder',
        taskName: folderName,
        ...config.partLevelFields,
      };
      // eslint-disable-next-line no-await-in-loop
      await dispatch('createNewTask', folder);

      tasksOrderCount++;

      // check for references
      const references = {};
      const newTaskIds = [];
      oldTasks.map((task, key) => {
        if (task.taskType === 'table' && task.jobId === config._id) {
          const newTaskId = uuidv4();
          references[task._id] = newTaskId;
          newTaskIds[key] = newTaskId;
        }
      });
      for (let i = 0; i < tasks.length; i++) {
        const task = { ...tasks[i] };
        if (task.taskType === 'table') {
          if (task.jobId === config._id) {
            delete task.taskNumber;
            task.folderId = folderId;
            task.taskOrder = tasksOrderCount;
            tasksOrderCount++;
            task.tablesConfigurations = config;
            task.jobId = data.jobId;
            task._id = newTaskIds[i];
            if (references) task.references = references;
            // eslint-disable-next-line no-await-in-loop
            await dispatch('createNewTask', task);
          }
        }
      }
    }
  },

  /** Sending data to database */
  createNewJob: async ({ dispatch, commit, state }, data) => {
    data.userLocation = state.currentUser.location;

    const resultSite = await client.get(`/sites/${state.currentUser.location}`);
    const { siteName } = resultSite.data;

    data.locationName = siteName;

    const tasks = data.tasks ? data.tasks : [];
    delete data.tasks;
    const result = await client.post('/job', data);
    if (result.status === 201) {
      commit('ADD_JOB', result.data);
      let tasksOrderCount = 0;
      if (data.tablesConfigurations) {
        tasksOrderCount = data.tablesConfigurations.length;
        const object = {
          tablesConfigurations: data.tablesConfigurations,
          tasks,
          jobId: result.data._id,
        };
        await dispatch('configureTasks', object);
      }
      for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        if (task.taskType !== 'table') {
          delete task.taskNumber;
          task.taskOrder = tasksOrderCount > 0 ? tasksOrderCount : i;
          if (tasksOrderCount) tasksOrderCount++;
          task.jobId = result.data._id;
          task._id = uuidv4();
          // eslint-disable-next-line no-await-in-loop
          await dispatch('createNewTask', task);
        }
      }
      return Promise.resolve(result.data._id);
    }
    commit('SET_ERROR_NEW_JOB', result.data);
    return Promise.resolve('');
  },

  /** Instruction inside task  */
  setCurrentTask: async ({ commit }, obj) => {
    const task = obj.job.childConfig[0].childConfig.find(
      (item) => item.taskNumber === obj.taskNumber
    );
    commit('SET_CURRENT_TASK', task);
  },

  duplicateTask: async ({ dispatch, state, commit }, task) => {
    let newTask = { ...task };
    let counter = 1;
    let newName = `${newTask.taskName} (copy ${counter})`;
    let index = 0;

    // to get the last task order number in between the folder
    if (task.folderId) {
      const folderTasks = state.tasks.filter(
        (folderTask) => folderTask.folderId === task.folderId
      );
      folderTasks.forEach((folderTask) => {
        if (index <= folderTask.taskOrder) {
          index = folderTask.taskOrder;
        }
      });
      index += 1;
    }
    // to get the last task order number outside the folder
    else {
      const nonfolderTasks = state.tasks.filter(
        (nonfolderTask) =>
          nonfolderTask.folderId === undefined || nonfolderTask.folderId === ''
      );
      nonfolderTasks.forEach((nonfolderTask) => {
        if (index <= nonfolderTask.taskOrder) {
          index = nonfolderTask.taskOrder;
        }
      });
      index += 1;
    }

    // get all taskNames
    state.tasks.some((item) => {
      // check for duplicate names
      if (newName === item.taskName) {
        newName = newName.replace(/\d+/g, ++counter);
      }
    });
    if (task.childConfig.length !== 0) {
      task.childConfig[0].taskName = newName;
    }

    task.taskName = newName;

    task._id = uuidv4();
    try {
      newTask = await dispatch('createNewTask', {
        ...task,
        taskType: task.taskType,
        jobId: state.currentJob._id,
        taskName: newName,
        taskOrder: index,
        modifier: state.currentUser,
        date: new Date(),
        // Use this in order to signal in backend to clear measurments
        isDuplicated: true,
      });
    } catch (err) {
      console.error('Failed to get new TaskNumber', err);
      return false;
    }
    commit('SET_NEW_TASK', newTask);
  },

  duplicateFolder: async ({ commit }, folder) => {
    try {
      const result = await client.post(`/task/duplicateFolder/${folder._id}`);
      commit(
        'SET_NEW_TASK',
        result.data.newFolders[result.data.newFolders.length - 1]
      );
    } catch (error) {
      console.error('Failed to duplicate folder', error);
    }
  },

  removeFavoriteTemplate: async ({ state, dispatch }, options) => {
    const newCount = Math.max(0, options.count - 1);
    const newFavoriteList = state.currentUser.favoriteList;
    const index = newFavoriteList.indexOf(options.nameId);
    if (index > -1) newFavoriteList.splice(index, 1);

    await client.put(`job/${options.id}/favoritecount`, { count: newCount });
    await dispatch('updateUser', {
      _id: state.currentUser._id,
      favoriteList: newFavoriteList,
    });
  },

  addFavoriteTemplate: async ({ state, dispatch }, options) => {
    const newCount = Math.max(0, options.count + 1);
    const newFavoriteList = state.currentUser.favoriteList || [];
    const index = newFavoriteList.indexOf(options.nameId);
    if (index === -1) newFavoriteList.push(options.nameId);

    await client.put(`job/${options.id}/favoritecount`, { count: newCount });
    await dispatch('updateUser', {
      _id: state.currentUser._id,
      favoriteList: newFavoriteList,
    });
  },

  updateUserSelf: async ({ commit }, user) => {
    const { data: updatedUser } = await client.put('users/current/', user);
    commit('UPDATE_USER', updatedUser);
  },

  updateUser: async ({ commit }, user) => {
    await client.put(`users/${user._id}`, user);
    commit('UPDATE_USER', user);
  },

  deleteUser: async ({ dispatch }, user) => {
    await client.delete(`users/${user._id}`);
    dispatch('DELETE_USER', user);
  },

  createUserSelf: async ({ commit }) => {
    const { data: createdUser } = await client.post('/users/current');
    commit('CREATE_USER', createdUser);
  },

  createUser: async ({ commit }, user) => {
    const result = await client.post('/users', user);
    commit('CREATE_USER', result.data);
  },

  approveUser: async ({ commit }, user) => {
    const approvedUser = await client.post(`users/${user._id}/approve`, user);
    commit('UPDATE_USER', approvedUser);
  },

  rejectUser: async ({ commit }, user) => {
    const rejectedUser = await client.post(`users/${user._id}/reject`);
    commit('UPDATE_USER', rejectedUser);
  },

  duplicateJob: async ({ commit, state }, job) => {
    const newJob = { ...job };
    newJob.name = uuidv4();
    newJob.partNumber = uuidv4();
    delete newJob._id;
    newJob.title = `${job.title} Copy`;
    newJob.favoriteCount = 0;
    newJob.prodCreatedByUser = state.currentUser;
    newJob.date = new Date().getTime();
    const result = await client.post(`/job/${job._id}/duplicate`, newJob);
    commit('ADD_JOB_BEFORE', { existingJob: job, newJob: result.data.newJob });
    return result.data.newJob._id;
  },

  deleteJob: async ({ commit }, id) => {
    const result = await client.delete(`/job/${id}`);
    if (result.status === 200) {
      commit('ADD_DELETED_JOB', result.data.deletedJob);
      commit('REMOVE_JOB', id);
    }
  },
  undeleteJob: async ({ commit }, id) => {
    const result = await client.put(`/job/${id}/undelete`);
    if (result.status === 200) {
      commit('ADD_JOB', result.data.undeletedJob);
      commit('REMOVE_DELETED_JOB', id);
    }
  },

  // Connection between OIL and DIJ
  getMaskedURL: async ({}, id) => {
    const result = await client.get(`/external/link/${id}`);
    return result.data;
  },

  getOilToken: async ({ commit }) => {
    const config = {
      headers: { apiKey: process.env.VUE_APP_DSSO_OIL_API_KEY },
    };
    const result = await oilApi.get('/Auth/token', config);
    const token = result.data.Token;
    commit('SET_OIL_TOKEN', token);
  },

  getOilApprovers: async ({ commit }, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await oilApi.get('/Oil/dij-creator-users', config);
    const approvers = result.data;
    commit('SET_OIL_APPROVERS', approvers);
  },

  updateApprovalStatus: async ({ commit, dispatch, state }, data) => {
    try {
      commit('SET_APPROVAL_ERROR', {});

      if (data.externalClient === 'OIL') {
        await dispatch('getOilToken');
        const token = state.oilToken;

        const oilData = {
          ApproverGid: data.selectedApprover.Gid,
          CurrentUserGid: data.userGid,
          TemplateId: data.templateId,
          ApprovalStatus: data.approvalStatus,
          Revision: data.job.revision,
        };

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await oilApi.post('/Oil/template-approval', oilData, config);
      }

      data.job.approvalStatus = data.approvalStatus;
      await dispatch('updateJob', data.job);

      commit('SET_TEMPLATE_IN_APPROVAL_LIST', data);
      commit('UPDATE_APPROVAL_STATUS', data);
    } catch (err) {
      commit('SET_APPROVAL_ERROR', {
        message: 'Error when trying to submit for appproval',
        err,
      });
      console.error('Error when trying to submit for approval', err);
    }
  },

  createDropDown: async ({}, data) => {
    try {
      await client.post('/dropdown', data);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject();
    }
  },

  editDropDown: async ({}, data) => {
    try {
      await client.put(`/dropdown/${data.id}`, { name: data.name });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject();
    }
  },

  deleteDropDown: async ({}, id) => {
    try {
      await client.delete(`/dropdown/${id}`);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject();
    }
  },
  notifyJobCompleted: async ({ dispatch }, data) => {
    if (data.client.includes('OIL')) {
      return dispatch('notifyOILJobCompleted', data.id);
    }
  },
  notifyOILJobCompleted: async ({ dispatch, state }, templateId) => {
    try {
      await dispatch('getOilToken');
      const token = state.oilToken;

      const oilData = {
        TemplateId: templateId,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await oilApi.post('/Oil/template-completed', oilData, config);
    } catch (err) {
      console.error(
        'Error when trying to notify oil that inspection is completed',
        err
      );
      throw err;
    }
  },
  createNewTask: async ({}, data) => {
    try {
      const result = await client.post('/task', data);
      return result.data;
    } catch (error) {
      console.error('Failed to create task', error);
    }
  },

  /**
   * Generic task update action. Requires lock.
   * If the lock isn't already acquired, an ad-hoc lock will be created and released after the update.
   */
  updateTask: async ({ commit, state, dispatch }, task) => {
    let updateState = true;
    if (task.updateState !== undefined) {
      updateState = task.updateState;
      task = task.task;
    }
    const id = task._id;
    // Make sure a lock is acquired
    const hasAdhocLockCreated = await dispatch('lockTaskIfNotLocked', task._id);
    await client.put(`task/${id}`, task);
    if (hasAdhocLockCreated) {
      await dispatch('unlockTask', task._id);
    }
    const index = state.jobTasks.findIndex((t) => t._id === task._id);
    if (updateState) {
      commit('SET_TASK_OBJECT', task);
    }
    commit('UPDATE_JOB_TASKS', { task, index });
  },

  updateTasksOrder: async (
    { dispatch, commit },
    { jobId, tasks, folderId }
  ) => {
    commit('SET_WAITING_FOR_WRITE', true);
    const tasksIds = [];
    const allTasks = await dispatch('getJobTasks', {
      jobId,
      allTasks: true,
      return: true,
    });
    let index = 0;
    if (!tasks || tasks.length === 0) {
      tasks = allTasks;
    }
    tasks.forEach((task) => {
      // Order tasks when inside a folder task list
      if (folderId) {
        const folder = allTasks.find((item) => item._id === folderId);
        if (index === 0) {
          index = folder.taskOrder + 1;
        }
        tasksIds.push({ id: task._id, order: index });
        task.taskOrder = index;
        index++;
        return;
      }
      // order tasks when in general tasks list
      if (task.taskType === 'folder') {
        // if there are folders order tasks inside folder
        tasksIds.push({ id: task._id, order: index });
        task.taskOrder = index;
        index++;
        const folderTasks = allTasks.filter(
          (folderTask) => folderTask.folderId === task._id
        );
        folderTasks.forEach((folderTask) => {
          tasksIds.push({ id: folderTask._id, order: index });
          index++;
        });
        return;
      }
      if (task.folderId) {
        return;
      }
      // order normal tasks
      tasksIds.push({ id: task._id, order: index });
      task.taskOrder = index;
      index++;
    });
    try {
      await client.patch('/tasks', tasksIds);
    } catch (err) {
      throw new Error(err);
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  setUpdatedTasks: async ({ commit }, updatedTasks) => {
    commit('SET_TASKS', updatedTasks);
  },

  deleteTask: async ({ commit }, id) => {
    const result = await client.delete(`task/${id}`);
    if (result.status === 200) {
      commit('REMOVE_TASK', id);
    }
  },
  undeleteTask: async ({}, id) => {
    await client.put(`task/${id}/undelete`);
  },

  getIsoNames: async ({}) => {
    const names = await client.get('iso/name');
    return names.data;
  },
  getIsoGrades: async ({}, name) => {
    const grades = await client.get(`iso/grade/${name}`);
    return grades.data;
  },
  getIsoValues: async ({}, options) => {
    const values = await client.get('iso/values', { params: options });
    return values.data;
  },
  getPartLevels: async ({ commit }) => {
    const result = await client.get('partlevel');
    commit('SET_PART_LEVELS', result.data);
  },

  getVisualConditions: async ({ commit }) => {
    const result = await client.get('visualconditions');
    commit('SET_VISUAL_CONDITIONS', result.data);

    return result.data;
  },

  async copyFromTask({}, { task, jobId }) {
    await client.post(`task/copy/${jobId}`, { task });
  },

  // This is not used any more
  // The clear measurments for a task is happening on back-end
  // CHECK if is used in any outher place and removed if not.
  clearTasksInputValues: async ({}, tasks) => {
    const freeFindingIndex = [];
    // const itemToRemove = [];

    tasks.forEach((task) => {
      delete task.reportNote;
      delete task.taskComment;
      delete task.partNumber;
      delete task.name;

      delete task.modifier;
      delete task.modifyTimeout;

      task.completenessPercentage = 0;
      task.workStatusCode = 0;

      if (
        task.childConfig &&
        task.childConfig.length > 0 &&
        task.childConfig[0].childConfig &&
        task.childConfig[0].childConfig.length > 0
      ) {
        if (task.childConfig[0].lastModifiedBy) {
          delete task.childConfig[0].lastModifiedBy;
        }

        task.childConfig[0].childConfig.forEach((innerChild, index1) => {
          const bubbleToRemove = [];
          innerChild.image = [];
          if (innerChild.lastModifiedBy) {
            delete innerChild.lastModifiedBy;
          }

          if (innerChild.childConfig && innerChild.childConfig.length > 0) {
            innerChild.childConfig.forEach(async (bubble, index) => {
              if (bubble.childconfig) {
                bubble.childconfig.forEach((element) => {
                  element.image = [];
                  delete element.findingsByInspector;
                });
              }
              if (bubble.tagName === 'INPUT-ELEMENTARRAY') {
                const newId = uuidv4();
                const oldId = bubble.id;
                bubble.id = `elementArray_${newId}`;
                const bubbleIndex =
                  task.childConfig[0].childConfig[1].childConfig.findIndex(
                    (element) => element.element_id === oldId
                  );
                if (
                  task.childConfig[0].childConfig[1] &&
                  task.childConfig[0].childConfig[1].childConfig &&
                  task.childConfig[0].childConfig[1].childConfig[bubbleIndex]
                ) {
                  task.childConfig[0].childConfig[1].childConfig[
                    bubbleIndex
                  ].element_id = bubble.id;
                }

                if (bubble.measurement_type === 'runout') {
                  if (bubble.inputValues && bubble.inputValues.length > 0) {
                    bubble.inputValues.forEach((input, key) => {
                      if (input) input.id = `position${key + 1}_${newId}`;
                    });
                  }
                  const marker =
                    task.childConfig[0].childConfig[1].childConfig[bubbleIndex];
                  if (
                    marker &&
                    marker.childConfig &&
                    marker.childConfig.length > 0
                  ) {
                    marker.childConfig.forEach((item, key) => {
                      item.element_id = `position${key + 1}_${newId}`;
                    });
                  }
                }
              }
              if (
                bubble.childConfig &&
                bubble.childConfig[0] &&
                bubble.childConfig[0].measurement_type === 'freefinding'
              ) {
                bubbleToRemove.push({ inner: index1, bubble: index });
              }

              if (bubble.image) {
                bubble.image = [];
              }

              if (bubble.childConfig && bubble.childConfig.length > 0) {
                bubble.childConfig.forEach((item) => {
                  if (item.image && item.image.length > 0) {
                    item.image = [];
                  }
                });
              }

              if (bubble.createdBy === 'emf-diagram-wizard-freefindingmarker') {
                task.childConfig[0].childConfig[index1].childConfig.splice(
                  index,
                  1
                );
              }
              if (bubble.lastModifiedBy) {
                delete bubble.lastModifiedBy;
              }
              if (bubble.childConfig && bubble.childConfig.length > 0) {
                delete bubble.lastModifiedBy;
              }
              if (bubble && bubble.measurement_type === 'freefinding') {
                // check free finding to remove the field from the task
                freeFindingIndex.push(index);
              } else if (bubble && bubble.inputValues) {
                if (bubble.findingsByInspector) {
                  bubble.findingsByInspector = '';
                }
                // clear all the other fields type
                for (let i = 0; i < bubble.inputValues.length; i++) {
                  if (bubble.inputValues[i]) {
                    delete bubble.inputValues[i];
                  }
                }
                // Diagram visual measurement_type and images
                if (bubble && bubble.childConfig) {
                  bubble.childConfig.forEach((field) => {
                    if (field.visualConditions) {
                      field.visualConditions = [];
                      field.type = '';
                    } else if (field.image) {
                      field.image = [];
                    }
                  });
                }
              }
            });
            if (bubbleToRemove.length > 0) {
              bubbleToRemove.forEach((obj) => {
                task.childConfig[0].childConfig[obj.inner].childConfig.splice(
                  obj.bubble,
                  1
                );
              });
            }
            // remove free finding fields from the tasks
            freeFindingIndex.forEach((position) => {
              // const positionToRemove = position;
              task.childConfig[0].childConfig[0].childConfig.splice(
                position,
                1
              );
            });
          } else {
            if (innerChild.yesOrNo) {
              innerChild.yesOrNo = '';
            }
            if (innerChild.mcValue) {
              innerChild.mcValue = [];
            }
            if (innerChild.checked) {
              innerChild.checked = false;
            }
            if (innerChild.image) {
              innerChild.image = [];
            }
            if (innerChild.inputValue) {
              innerChild.inputValue = '';
            }
            if (innerChild.tableData) {
              innerChild.tableData.forEach((tableRow, index) => {
                if (index > 0) {
                  for (let i = 1; i < tableRow.length; i++) {
                    if (i > 0 && tableRow[i]) {
                      tableRow[i] = '';
                    }
                  }
                }
              });
            }
          }
        });
      }
    });
    return tasks;
  },

  setFolderName: ({ commit }, folder) => {
    commit('SET_FOLDER_NAME', folder);
  },

  syncImagesOnIndexedDB: async ({}) => {
    const dijConfigurations = new DIJVueConfigurations();

    const pouchDB = new PouchDB('dij-offline');

    const { rows: items } = await pouchDB.allDocs({
      include_docs: true,
      attachments: true,
    });

    items.forEach(async (item) => {
      if (item.doc && item.doc.file) {
        // the saved path has the name of the object and the path itself
        // we have to remove the name (key)
        const positionToSplit = item.doc.path.lastIndexOf('/');
        let newPath = item.doc.path.substring(0, positionToSplit);

        // if the path still has a '/' at the beginning, remove it
        if (newPath.charAt(0) === '/') {
          newPath = newPath.slice(0, 0) + newPath.slice(1);
        }

        try {
          if (!item.doc.retries || item.doc.retries < 5) {
            await dijConfigurations.uploadFileToS3(
              item.doc.file,
              item.doc.suggestedFilename,
              newPath
            );

            await pouchDB.remove(item.doc);
          } else {
            console.error('Number of retries exceeded, item: ', item.doc);
          }
        } catch (err) {
          if (!item.doc.retries) {
            item.doc.retries = 1;
          } else {
            item.doc.retries += 1;
          }

          console.error('failed to upload file', err, item.doc);
          await pouchDB.put(item.doc);
        }
      }
    });
  },
  getJobsByWorkOrderNumber: async ({ commit }, workOrderNumber) => {
    commit('SET_WAITING_FOR_READ', true);
    try {
      const result = await client.get(
        `/job/workOrderNumber/${workOrderNumber}`
      );
      return result.data.jobs;
    } finally {
      commit('SET_WAITING_FOR_READ', false);
    }
  },

  addRepairAssessmentRow: async (
    { commit },
    { job, rows, tool, replace = false, replaceMinor = false }
  ) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      const result = await client.post(
        `/job/${job._id}/ra/`,
        { rows, replace, replaceMinor },
        {
          params: { tool },
        }
      );
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  updateRepairAssessmentRow: async ({ commit }, { job, row, tool }) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      const result = await client.put(`/job/${job._id}/ra/${row.id}`, row, {
        params: { tool },
      });
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  moveRepairAssessmentRow: async (
    { commit },
    { job, firstRow, secondRow, tool }
  ) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      const result = await client.put(
        `/job/${job._id}/ra/${firstRow.id}/${secondRow.id}`,
        undefined,
        {
          params: { tool },
        }
      );
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  deleteRepairAssessmentRow: async ({ commit }, { job, row, tool }) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      const result = await client.delete(`/job/${job._id}/ra/${row.id}`, row, {
        params: { tool },
      });
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  lockRepairAssessmentRow: async ({ commit }, { job, row, tool }) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      const result = await client.post(`/job/${job._id}/ra/${row.id}/lock`, {
        params: { tool },
      });
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  unlockRepairAssessmentRow: async ({ commit }, { job, row, tool }) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      const result = await client.post(`/job/${job._id}/ra/${row.id}/unlock`, {
        params: { tool },
      });
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  getRepairAssessment: async ({ commit }, job) => {
    commit('SET_WAITING_FOR_READ', true);
    try {
      const result = await client.get(`/job/${job._id}/ra/`);
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_READ', false);
    }
  },

  deleteRepairAssessment: async ({ commit }, { job, tool }) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      const result = await client.delete(`/job/${job._id}/ra/`, {
        params: { tool },
      });
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  lockRepairAssessment: async ({ commit }, { job, tool }) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      const result = await client.post(`/job/${job._id}/ra/lock`, {
        params: { tool },
      });
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  unlockRepairAssessment: async ({ commit }, { job, tool }) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      const result = await client.post(`/job/${job._id}/ra/unlock`, {
        params: { tool },
      });
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  setWaitingForLoad: async ({ commit }, waitingForLoad) => {
    commit('SET_WAITING_FOR_WRITE', waitingForLoad);
  },

  setWaitingForWrite: async ({ commit }, waitingForWrite) => {
    commit('SET_WAITING_FOR_WRITE', waitingForWrite);
  },

  countUserOwnedTemplates: async ({ commit, state }, userId) => {
    commit('SET_WAITING_FOR_READ', true);
    try {
      let result;
      if (!userId) {
        result = await client.get(
          `/users/${state.currentUser._id}/countOwnedTemplates`
        );
      } else {
        result = await client.get(`/users/${userId}/countOwnedTemplates`);
      }
      commit('SET_USER_OWNED_TEMPLATES_COUNT', result.data);
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_READ', false);
    }
  },

  countUserOwnedJobs: async ({ commit, state }, userId) => {
    commit('SET_WAITING_FOR_READ', true);
    try {
      let result;
      if (!userId) {
        result = await client.get(
          `/users/${state.currentUser._id}/countOwnedJobs`
        );
      } else {
        result = await client.get(`/users/${userId}/countOwnedJobs`);
      }
      commit('SET_USER_OWNED_JOBS_COUNT', result.data);
      return result.data;
    } finally {
      commit('SET_WAITING_FOR_READ', false);
    }
  },

  processJobChanges: async ({ commit }, id) => {
    const { data: job } = await client.get(`/job/${id}`);
    commit('UPDATE_JOB_IN_LIST', job);
  },

  moveTasks: async ({}, { tasks, folder }) => {
    const tasksId = tasks.map((task) => task._id);
    const folderId = folder._id;
    await client.put('/task/move', { tasksId, folderId });
  },

  getMteTypes: async ({ commit }) => {
    const { data } = await client.get('/mteType');
    commit('SET_MTE_TYPES', data);
    return data;
  },

  getMteType: async ({ commit, state }, id) => {
    let mteType;
    const { mteTypes } = state;
    if (mteTypes.length > 0) {
      mteType = mteTypes.find((element) => element._id === id);
    } else {
      const { data } = await client.get(`/mteType/${id}`);
      mteType = data;
    }
    commit('SET_MTE_TYPE', mteType);
    return mteType;
  },

  saveMteType: async ({ commit }, mteType) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      // If there's an id, update the item
      if (mteType._id) {
        const { data } = await client.patch(`/mteType/${mteType._id}`, mteType);
        commit('REPLACE_MTE_TYPE', data);
        return data;
      }

      // If there's no id, create the item
      const { data } = await client.post('/mteType', mteType);
      commit('ADD_MTE_TYPE', data);
      return data;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  deleteMteType: async ({ commit }, mteType) => {
    commit('SET_WAITING_FOR_WRITE', true);
    try {
      await client.delete(`/mteType/${mteType._id}`);
      commit('DELETE_MTE_TYPE', mteType._id);
      return mteType;
    } finally {
      commit('SET_WAITING_FOR_WRITE', false);
    }
  },

  getMteMasterTypes: async ({ state, dispatch }) => {
    if (state.mteTypes.length === 0) {
      await dispatch('getMteTypes');
    }

    return state.mteTypes.filter((mteType) => mteType.isMaster);
  },

  getMteNeedsMasterTypes: async ({ state, dispatch }) => {
    if (state.mteTypes.length === 0) {
      await dispatch('getMteTypes');
    }

    return state.mteTypes.filter((mteType) => mteType.needsMaster);
  },

  createMte: async ({ dispatch }, { task, mte }) => {
    const result = await client.post(`/task/${task._id}/mte/`, mte);
    await dispatch('getTask', task._id);
    return result;
  },

  updateMte: async ({ dispatch }, { task, mte }) => {
    const result = await client.put(`/task/${task._id}/mte/${mte.id}`, mte);
    await dispatch('getTask', task._id);
    return result;
  },

  deleteMte: async ({ dispatch }, { task, mte }) => {
    const result = await client.delete(`/task/${task._id}/mte/${mte.id}`);
    await dispatch('getTask', task._id);
    return result;
  },

  updateTaskReportNote: async ({ dispatch }, { taskId, reportNote }) => {
    const hasAdhocLockCreated = await dispatch('lockTaskIfNotLocked', taskId);
    await client.put(`/task/${taskId}`, { reportNote });
    if (hasAdhocLockCreated) {
      await dispatch('unlockTask', taskId);
    }
  },

  lockTask: async ({ commit }, id) => {
    await client.post(`/task/${id}/lock`);
    commit('ADD_LOCKED_TASK_ID', id);
  },

  unlockTask: async ({ commit }, id) => {
    await client.post(`/task/${id}/unlock`);
    commit('REMOVE_LOCKED_TASK_ID', id);
  },

  /**
   * Lock if there's no active lock. Returns true if a new lock was created, false otherwise.
   */
  lockTaskIfNotLocked: async ({ state, dispatch }, id) => {
    const hasLock = state.lockedTaskIds.some((taskId) => taskId === id);
    if (hasLock) {
      return false;
    }
    await dispatch('lockTask', id);
    return true;
  },

  setInfoMessage: async ({ commit }, message) => {
    commit('SET_INFO_MESSAGE', message);
  },

  setSuccessMessage: async ({ commit }, message) => {
    commit('SET_SUCCESS_MESSAGE', message);
  },

  setWarningMessage: async ({ commit }, message) => {
    commit('SET_WARNING_MESSAGE', message);
  },

  setErrorMessage: async ({ commit }, message) => {
    commit('SET_ERROR_MESSAGE', message);
  },

  clearAllMessages: async ({ commit }) => {
    commit('CLEAR_INFO_MESSAGE');
    commit('CLEAR_SUCCESS_MESSAGE');
    commit('CLEAR_WARNING_MESSAGE');
    commit('CLEAR_ERROR_MESSAGE');
  },

  getAnnouncementBanner: async ({ commit, state }, forceGet) => {
    // Check if the messageTimeout has passed
    // If it did get the message and update timestamp
    //  If the message is different from the one we have cached, show it to the user
    //  If the message is the same continue
    // If not continue
    const dataTimeout = state.announcementBannerDataTimeout;
    const now = new Date().getTime();
    const hasDataTimeout = dataTimeout < now;
    if (forceGet || hasDataTimeout) {
      const {
        data: { value: message },
      } = await client.get('/settings/Announcement Banner Message');
      const {
        data: { value: startDate },
      } = await client.get('/settings/Announcement Banner Start Date');
      const {
        data: { value: endDate },
      } = await client.get('/settings/Announcement Banner End Date');
      commit('SET_ANNOUNCEMENT_BANNER', { message, startDate, endDate });
    }
    return {
      message: state.announcementBannerMessage,
      startDate: state.announcementBannerStartDate,
      endDate: state.announcementBannerEndDate,
    };
  },

  previewAnnouncementBanner: async ({ commit }, { message }) => {
    const now = new Date();
    const nowPlusOneMinute = new Date(now.getTime() + 60 * 1000);
    commit('SET_ANNOUNCEMENT_BANNER', {
      message,
      startDate: now,
      endDate: nowPlusOneMinute,
    });
  },

  saveAnnouncementBanner: async (
    { commit },
    { message, startDate, endDate }
  ) => {
    await client.put('/settings/Announcement Banner Message', {
      value: message,
    });
    await client.put('/settings/Announcement Banner Start Date', {
      value: startDate,
    });
    await client.put('/settings/Announcement Banner End Date', {
      value: endDate,
    });
    commit('SET_ANNOUNCEMENT_BANNER', { message, startDate, endDate });
  },

  hideAnnouncementBanner: async ({ commit }) => {
    commit('HIDE_ANNOUNCEMENT_BANNER');
  },
};
export default actions;
