import websocketService from '../../../websocketService';
import {
  WEBSOCKET_EVENT_ADD_SUBSCRIBER,
  WEBSOCKET_EVENT_PUBLISH_MESSAGE,
  WEBSOCKET_EVENT_REMOVE_SUBSCRIBER,
} from '../mutations/mutationTypes';
import { SEND_WEBSOCKET_MESSAGE } from './actionTypes';

export default {
  async UPDATE_WEB_SOCKET_CONNECTION_ID({ commit }, connectionId) {
    commit('SET_WEB_SOCKET_CONNECTION_ID', connectionId);
  },

  async UPDATE_WEB_SOCKET_STATUS({ commit }, status) {
    commit('SET_WEBSOCKET_STATUS', status);
  },

  async SEND_WEBSOCKET_MESSAGE({ state }, payload) {
    const { action, data } = payload;

    // Validate action and data early to avoid unnecessary checks later
    if (!action) {
      console.error(
        'SEND_WEBSOCKET_MESSAGE: action parameter is missing from payload'
      );
      return;
    }

    if (!data) {
      console.error(
        'SEND_WEBSOCKET_MESSAGE: data parameter is missing from payload'
      );
      return;
    }

    const maxWaitTime = 10000; // Set a maximum wait time for connection (e.g., 10 seconds)
    let elapsedTime = 0;
    const intervalTime = 100; // Check connection status every 100ms

    const waitingInterval = setInterval(() => {
      elapsedTime += intervalTime;

      // Check WebSocket status or allow if action is 'registerToken'
      if (
        state.websocketStatus !== 'Disconnected' ||
        action === 'registerToken'
      ) {
        clearInterval(waitingInterval); // Stop checking

        try {
          // Send the WebSocket message inside a try-catch block
          console.log(`SENDING MESSAGE for action: ${action}`);
          websocketService.sendMessage(payload);
        } catch (error) {
          console.error(
            `Error while sending WebSocket message for action: ${action}`,
            error
          );
        }
      } else if (elapsedTime >= maxWaitTime) {
        // Stop waiting if max time is exceeded
        clearInterval(waitingInterval);
        console.error(
          'SEND_WEBSOCKET_MESSAGE: Timed out waiting for WebSocket connection.'
        );
      } else if (elapsedTime % 1000 === 0) {
        console.log('Waiting for WebSocket to connect...');
      }
    }, intervalTime);
  },

  WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION({ commit }, { action, callback }) {
    commit(
      WEBSOCKET_EVENT_ADD_SUBSCRIBER,
      { action, callback },
      { root: true }
    );
  },

  WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION({ commit }, { action, callback }) {
    commit(
      WEBSOCKET_EVENT_REMOVE_SUBSCRIBER,
      { action, callback },
      { root: true }
    );
  },

  WEBSOCKET_EVENT_HANDLE_MESSAGE({ commit }, message) {
    try {
      const { action, data } = message;

      // Publish message to all subscribers of this action
      commit(WEBSOCKET_EVENT_PUBLISH_MESSAGE, { action, data }, { root: true });
    } catch (error) {
      console.error('Failed to handle WebSocket message:', error);
    }
  },

  WEBSOCKET_EVENT_ACQUIRE_LOCK({ dispatch }, bubbleId) {
    try {
      dispatch(
        SEND_WEBSOCKET_MESSAGE,
        { action: 'acquireBubbleLock', data: bubbleId },
        { root: true }
      );
    } catch (error) {
      console.error('Failed to handle WebSocket message:', error);
    }
  },

  WEBSOCKET_EVENT_RELEASE_LOCK({ dispatch }, bubbleId) {
    try {
      dispatch(
        SEND_WEBSOCKET_MESSAGE,
        { action: 'releaseBubbleLock', data: bubbleId },
        { root: true }
      );
    } catch (error) {
      console.error('Failed to handle WebSocket message:', error);
    }
  },
};
