const MODULE_NAME = 'WebsocketModule';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_WEB_SOCKET_STATUS = `${MODULE_NAME}/UPDATE_WEB_SOCKET_STATUS`;
export const UPDATE_WEB_SOCKET_CONNECTION_ID = `${MODULE_NAME}/UPDATE_WEB_SOCKET_CONNECTION_ID`;

export const SEND_WEBSOCKET_MESSAGE = `${MODULE_NAME}/SEND_WEBSOCKET_MESSAGE`;

export const WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION = `${MODULE_NAME}/WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION`;
export const WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION = `${MODULE_NAME}/WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION`;
export const WEBSOCKET_EVENT_HANDLE_MESSAGE = `${MODULE_NAME}/WEBSOCKET_EVENT_HANDLE_MESSAGE`;

export const WEBSOCKET_EVENT_ACQUIRE_LOCK = `${MODULE_NAME}/WEBSOCKET_EVENT_ACQUIRE_LOCK`;
export const WEBSOCKET_EVENT_RELEASE_LOCK = `${MODULE_NAME}/WEBSOCKET_EVENT_RELEASE_LOCK`;
